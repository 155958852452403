import gql from "graphql-tag";

export const RESEND_VERIFICATION_CODE_ITP = gql`
  mutation ResendITPToken($token: String!, $email: String!) {
    resendITPToken(token: $token, email: $email) {
      status
      message
    }
  }
`;

export const SEND_VERIFICATION_CODE_ITP = gql`
  mutation SendITPVerificationEmail($email: String!) {
    sendITPVerificationEmail(email: $email) {
      status
      message
    }
  }
`;

export const CREATE_ITP_ACCOUNT = gql`
 mutation CreateITPAccount(
  $token: String!,
  $fName: String!,
  $lName: String!,
  $password: String!,
  $username: String!,
  $programmingLangs: [String],
  $frameworksAndLibs: [String],
  $databases: [String],
  $yearsOfExperience: Int,
  $otherSkills: [String],
  $spokenLanguage: [String],
  $referredBy: String) {
  createITPAccount(
    token: $token,
    fName: $fName,
    lName: $lName,
    password: $password,
    username: $username,
    programmingLangs: $programmingLangs,
    frameworksAndLibs: $frameworksAndLibs,
    databases: $databases,
    yearsOfExperience: $yearsOfExperience,
    otherSkills: $otherSkills,
    spokenLanguage: $spokenLanguage,
    referredBy: $referredBy) {
    jwt
  }
}
`;

export const CREATE_ITP_SSO_ACCOUNT = gql`
  mutation CreateITPSSOAccount(
    $token: String!
    $fName: String!
    $lName: String!
    $username: String!
    $programmingLangs: [String]
    $frameworksAndLibs: [String]
    $databases: [String]
    $yearsOfExperience: Int
    $otherSkills: [String],
    $spokenLanguage: [String],
    $referredBy: String) {
    createITPSSOAccount(
      token: $token
      fName: $fName
      lName: $lName
      username: $username
      programmingLangs: $programmingLangs
      frameworksAndLibs: $frameworksAndLibs
      databases: $databases
      yearsOfExperience: $yearsOfExperience
      otherSkills: $otherSkills,
      spokenLanguage: $spokenLanguage,
      referredBy: $referredBy) {
        jwt
    }
  }
`;

export const SAVE_VACANCY = gql`
  mutation saveVacancy($candidateId: ID!, $vacancyId: ID!) {
    saveVacancy(candidateId: $candidateId, vacancyId : $vacancyId ) {
      status
      message
    }
  }
`;

export const UNSAVE_VACANCY = gql`
  mutation unsaveVacancy($candidateId: ID!, $vacancyId: ID!) {
    unsaveVacancy(candidateId: $candidateId, vacancyId : $vacancyId ) {
      status
      message
    }
  }
`;

export const DISLIKE_VACANCY = gql`
  mutation disLikeVacancy($candidateId: ID!, $vacancyId: ID!) {
    disLikeVacancy(candidateId: $candidateId, vacancyId : $vacancyId ) {
      status
      message
    }
  }
`;

export const UPDATE_CANDIDATE = gql`
  mutation updateCandidate($candidateId: ID!, $candidateObj: candidateUpdateInput!) {
    updateCandidate(candidateId: $candidateId, candidateObj : $candidateObj ) {
      status
      message
    }
  }
`;
export const DEACTIVATE_ITP = gql`
 mutation AuthITPDeactivateAcc($pass: String!) {
  authITPDeactivateAcc(pass: $pass) {
    message
    status
  }
}
`;
