/* ************************************************************** */
/* ************** This file is part of the IT-Me **************** */
/* ************************************************************** */

import {
  ApolloClient, ApolloLink, ApolloProvider, concat, from, HttpLink, split, InMemoryCache,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { onError } from "@apollo/client/link/error";
import React, {
  useContext, useEffect, useLayoutEffect, useRef,
} from "react";
import { ThemeProvider } from "react-jss";
import ReactNotification from "react-notifications-component";
import {
  Route, Routes, useLocation, useNavigate,
  Navigate,
} from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { useFullscreen } from "react-use";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import {
  createClient as createWagmiClient, WagmiConfig, configureChains,
} from "wagmi";
import { polygon } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import ThemeContext from "../contexts/themeContext";

import COLORS from "../common/enumColors";
import { Toast, ToastContainer } from "../components/bootstrap/Toasts";
import { getOS } from "../helpers/helpers";
import useDarkMode from "../hooks/useDarkMode";
import Portal from "../layout/Portal/Portal";
import Wrapper from "../layout/Wrapper/Wrapper";
import Aside from "./layouts/Aside/Aside.itme";
import { AuthMenu, LandingMenu, MenuWithoutAside } from "./menu.itme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  getOS();

  const navigate = useNavigate();
  const location = useLocation();

  // create httplink for BE
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });
  const authToken = localStorage.getItem("token");

  // eslint-disable-next-line no-unused-vars
  const gqlWsLink = new GraphQLWsLink(
    createClient({
      url: process.env.REACT_APP_GQLWS_URL,
      shouldRetry: () => true,
      cache: new InMemoryCache(),
      // eslint-disable-next-line arrow-body-style
      connectionParams: () => {
        return {
          Authorization: authToken,
        };
      },
    }),
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" && definition.operation === "subscription"
      );
    },
    gqlWsLink,
    httpLink,
  );

  // check token validity - time and stuff

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: authToken,
      },
    }));

    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, forward, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }) => {
        if (extensions.code === 401) {
          localStorage.clear();
          return navigate(AuthMenu.commonLogin.path);
        }
        if (location === AuthMenu.commonLogin.path || location === AuthMenu.verifyRecruiterEmail.path || AuthMenu.forgotPassword.path) {
          return forward(operation);
        }
        return forward(operation);
      });
    }
  });

  // initalise apollo client
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, concat(authMiddleware, splitLink)]),
  });

  /**
   * Dark Mode
   */
  const { themeStatus, darkModeStatus } = useDarkMode();
  const theme = {
    theme: themeStatus,
    primary: COLORS.PRIMARY.code,
    secondary: COLORS.SECONDARY.code,
    success: COLORS.SUCCESS.code,
    info: COLORS.INFO.code,
    warning: COLORS.WARNING.code,
    danger: COLORS.DANGER.code,
    dark: COLORS.DARK.code,
    light: COLORS.LIGHT.code,
  };

  useEffect(() => {
    if (darkModeStatus) {
      document.documentElement.setAttribute("theme", "dark");
    }
    return () => {
      document.documentElement.removeAttribute("theme");
    };
  }, [darkModeStatus]);

  /**
   * Full Screen
   */
  const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
  const ref = useRef(null);
  useFullscreen(ref, fullScreenStatus, {
    onClose: () => setFullScreenStatus(false),
  });

  /**
   * Modern Design
   */
  useLayoutEffect(() => {
    if (process.env.REACT_APP_MODERN_DESIGN === "true") {
      document.body.classList.add("modern-design");
    } else {
      document.body.classList.remove("modern-design");
    }
  });

  //  Add paths to the array that you don't want to be "Aside".
  const withOutAsidePages = [
    AuthMenu.createRecruiter.path,
    AuthMenu.verifyRecruiterEmail.path,
    AuthMenu.verifyITPEmail.path,
    AuthMenu.commonLogin.path,
    AuthMenu.forgotPassword.path,
    AuthMenu.changePassword.path,
    MenuWithoutAside.createInvitedRecruiter.path,
    MenuWithoutAside.signupITPaccount.path,
    MenuWithoutAside.page404.path,
    MenuWithoutAside.inDevelopment.path,
    LandingMenu.recruiterLandingPage.path,
    LandingMenu.iTPLandingPage.path,
    LandingMenu.chooseYourPathPage.path,
  ];

  const userType = localStorage.getItem("userType");
  if (!userType) {
    withOutAsidePages.push(MenuWithoutAside.viewDetailedJobVacancy.path);
  }

  const { chains, provider } = configureChains(
    [polygon],
    [publicProvider()],
  );

  const metaMaskConnector = new MetaMaskConnector({
    chains,
    options: {
      shimChainChangedDisconnect: true,
    },
  });

  const wagmiClient = createWagmiClient({
    autoConnect: true,
    connectors: [metaMaskConnector],
    provider,
  });

  return (
    <WagmiConfig client={wagmiClient}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <ToastProvider components={{ ToastContainer, Toast }}>
            <div
              ref={ref}
            // Hide mobile view by adding "hidden-mobile" class
              className="app hidden-mobile"
              style={{
                backgroundColor: fullScreenStatus && "var(--bs-body-bg)",
                zIndex: fullScreenStatus && 1,
                overflow: fullScreenStatus && "scroll",
              }}
            >
              <Routes>
                {/* Todo special redirect to directly point to ITP page */}
                <Route path="/" element={<Navigate replace to="/ITP?ref=ITP" />} />
                {withOutAsidePages.map((path) => (
                  <Route key={path} path={path} />
                ))}
                <Route path="*" element={<Aside />} />
              </Routes>
              <Wrapper />
            </div>
            <Portal id="portal-notification">
              <ReactNotification />
            </Portal>
          </ToastProvider>
        </ThemeProvider>
      </ApolloProvider>
    </WagmiConfig>
  );
}

export default App;
