import React from "react";
import { AsideMenu, ITPAsideMenu, MenuWithoutAside } from "../menu.itme";
import DefaultNavbar from "../layouts/Navbar/Navbar.itme";

const headers = [
  { path: AsideMenu.recruiterDashboard.path, element: <DefaultNavbar pageTitle={AsideMenu.recruiterDashboard.text} />, exact: true },
  { path: AsideMenu.createJobVacancy.path, element: <DefaultNavbar pageTitle={AsideMenu.createJobVacancy.text} />, exact: true },
  { path: AsideMenu.viewJobVacancies.path, element: <DefaultNavbar pageTitle={AsideMenu.viewJobVacancies.text} />, exact: true },
  { path: MenuWithoutAside.viewDetailedJobVacancy.path, element: <DefaultNavbar pageTitle={MenuWithoutAside.viewDetailedJobVacancy.text} />, exact: true },
  { path: MenuWithoutAside.editJobVacancy.path, element: <DefaultNavbar pageTitle={MenuWithoutAside.editJobVacancy.text} />, exact: true },
  { path: AsideMenu.viewTeamMembers.path, element: <DefaultNavbar pageTitle={AsideMenu.viewTeamMembers.text} />, exact: true },
  { path: AsideMenu.viewCandidate.path, element: <DefaultNavbar pageTitle={AsideMenu.viewCandidate.text} />, exact: true },
  { path: AsideMenu.savedCandidates.path, element: <DefaultNavbar pageTitle={AsideMenu.savedCandidates.text} />, exact: true },
  { path: ITPAsideMenu.jobVacancies.path, element: <DefaultNavbar />, exact: true },
  { path: MenuWithoutAside.viewDetailedCandidate.path, element: <DefaultNavbar pageTitle={MenuWithoutAside.viewDetailedCandidate.text} />, exact: true },
  { path: ITPAsideMenu.viewProfile.path, element: <DefaultNavbar />, exact: true },
  { path: ITPAsideMenu.referAFriend.path, element: <DefaultNavbar />, exact: true },
  { path: AsideMenu.chat.path, element: <DefaultNavbar pageTitle={AsideMenu.chat.text} />, exact: true },
  { path: MenuWithoutAside.dashboardEarn.path, element: <DefaultNavbar />, exact: true },
  { path: MenuWithoutAside.dasboardLearn.path, element: <DefaultNavbar />, exact: true },
  { path: MenuWithoutAside.dashboardConnect.path, element: <DefaultNavbar />, exact: true },
  { path: MenuWithoutAside.dashboardBuild.path, element: <DefaultNavbar />, exact: true },
];

export default headers;
