const SuccessState = {
  SUCCESS: "SUCCESS",
  FAILED: "ERROR",
};
export const RECRUITER_STATUS = {
  ACTIVE: { name: "ACTIVE", color: "light-green", value: "ACTIVE" },
  PENDING: { name: "PENDING", color: "light-red", value: "PENDING" },
  IN_ACTIVE: { name: "IN_ACTIVE", color: "light-dark", value: "IN ACTIVE" },
};

export const RECRUITER_STATUS_ENUM = {
  ACTIVE: "ACTIVE",
  PENDING: "PENDING",
  IN_ACTIVE: "IN_ACTIVE",
};

export const RecruiterErrorCodes = {
  RECRUITER_ALREADY_EXISTING: "RECRUITER_ALREADY_EXISTING",
  EMAIL_NOT_FOUND: "USER_NOT_FOUND",
  INCORRECT_PASSWORD: "INCORRECT_PASSWORD",
  RECRUITER_NOT_FOUND: "RECRUITER_NOT_FOUND",
  VERIFICATION_TOKEN_INVALID: "VERIFICATION_TOKEN_INVALID",
  EQUAL_OLD_PASSWORD: "EQUAL_OLD_PASSWORD",
  ORGANIZATION_TAX_ID_NOT_FOUND: "ORGANIZATION_TAX_ID_NOT_FOUND",
  GENERAL_ERROR: "GENERAL_ERROR",
};

export const VERIFY_TOKEN = "VERIFY_TOKEN";

export default SuccessState;
