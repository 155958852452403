/* ************************************************************** */
/* ************** This file is part of the IT-Me **************** */
/* ************************************************************** */

import React from "react";
import { Route, Routes } from "react-router-dom";
import headers from "../../itme/routes/headerRoutes.itme";

function HeaderRoutes() {
  return (
    <Routes>
      {headers.map((page) => (

        <Route key={page.path} {...page} />
      ))}
    </Routes>
  );
}

export default HeaderRoutes;
