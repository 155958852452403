import React from "react";
import PropTypes from "prop-types";
import Button from "../../../../components/bootstrap/Button";
import Spinner from "../../../../components/bootstrap/Spinner";

// ==================== Button Component with Loader ==========================
function LoaderButton({
  name, size, icon, onClick, loading, loadingText, label, color, className, isLight, isOutline, isDisable,
}) {
  LoaderButton.propTypes = {
    name: PropTypes.string,
    size: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    loadingText: PropTypes.string,
    label: PropTypes.string.isRequired,
    color: PropTypes.string,
    className: PropTypes.string,
    isLight: PropTypes.bool,
    isOutline: PropTypes.bool,
    isDisable: PropTypes.bool,
  };

  LoaderButton.defaultProps = {
    name: "",
    size: null,
    icon: "",
    loading: false,
    loadingText: "Saving",
    color: null,
    className: null,
    isLight: false,
    isOutline: false,
    isDisable: false,
  };

  return (
    <Button
      name={name}
      size={size}
      color={color}
      isLight={isLight}
      isOutline={isOutline}
      icon={loading ? "" : icon}
      shadow="none"
      hoverShadow="lg"
      onClick={onClick}
      className={`${className} ${!color ? "btn-type-default" : null}`} // Set button color as gradient color when button color is null
      isDisable={isDisable}
    >
      {loading ? (
        <>
          <Spinner isGrow isSmall />
          {" "}
          {loadingText}
          {" "}
          ...
        </>
      ) : (
        label
      )}
    </Button>
  );
}

export default LoaderButton;
