/* ************************************************************** */
/* ************** This file is part of the IT-Me **************** */
/* ************************************************************** */

import React from "react";
import { Route, Routes } from "react-router-dom";
import footers from "../../itme/routes/footerRoutes.itme";

function FooterRoutes() {
  return (
    <Routes>
      {footers.map((page) => (
        <Route key={page.path} {...page} />
      ))}
    </Routes>
  );
}

export default FooterRoutes;
