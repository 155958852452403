import { motion } from "framer-motion";
import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import {
  AsideMenu, AuthMenu, MenuWithoutAside, LandingMenu, ITPAsideMenu, SuperAdminMenu,
} from "../menu.itme";

// eslint-disable-next-line react/prop-types
function ProtectedRoute({
  children, pageContext, customRedirect, tokenProtected,
}) {
  // check logged in state and set user details, dont use sessionStorage use localStorage
  const userToken = localStorage.getItem("token");
  const userType = localStorage.getItem("userType");

  let userAuthenticated = false;
  if (userToken) {
    userAuthenticated = true;
  }

  // this means that the link to this page requires a valid token to interact with
  if (tokenProtected === true) {
    tokenProtected = "";
  }

  // the user status and login status can never be different
  // eslint-disable-next-line react/prop-types
  if (pageContext.loginStatus !== userAuthenticated) {
    // eslint-disable-next-line react/prop-types
    return <Navigate to={pageContext.redirectURL} replace />;
  }

  // check if login status is equal to required status for access
  // eslint-disable-next-line react/prop-types
  if (pageContext.loginStatus === userAuthenticated) {
    // eslint-disable-next-line react/prop-types
    if (pageContext.accessLevel === "ANY") {
      return children;
    }
    // if usertype does not match then redirect to 404
    /**
     * If the user status is matching but the access levels dont match, then redirect to 404
     * * */
    // eslint-disable-next-line react/prop-types
    if (!pageContext.accessLevel.includes(userType)) {
      if (customRedirect === false) {
        // eslint-disable-next-line react/prop-types
        return <Navigate to={pageContext.redirectURL} replace />;
      }
      if (customRedirect === true) {
        return children;
      }

      // return <Navigate to={MenuWithoutAside.inDevelopment.path} replace />;
    }
    // if usertype matches then proceed
    // eslint-disable-next-line react/prop-types
    if (pageContext.accessLevel.includes(userType)) {
      return children;
    }
  }
  // eslint-disable-next-line react/prop-types
  return <Navigate to={pageContext.redirectURL} replace />;
}

// eslint-disable-next-line react/prop-types
function DashboardSwitch() {
  // this method is used to switch dashboard for the user based on usertype
  const userType = localStorage.getItem("userType");
  if (userType) {
    if (userType === "ADMIN") {
      return <RECRUITER.RECRUITER_DASHBOARD />;
    }
    if (userType === "RECRUITER") {
      return <RECRUITER.RECRUITER_DASHBOARD />;
    }
    if (userType === "VIEWER") {
      return <RECRUITER.RECRUITER_DASHBOARD />;
    }
    if (userType === "ITP") {
      return <ITP.ITP_DASHBOARD />;
    }
  }
  return <STATUS_AND_MISC.NOT_FOUND_PAGE_404 />;
}

function DetailedVacancySwitch() {
  const userType = localStorage.getItem("userType");
  if (userType) {
    if (userType === "ITP") {
      return <ITP.VIEW_DETAILED_JOB />;
    }
    return <JOB_VACANCIES.VIEW_DETAILED_JOB />;
  }
  return <JOB_VACANCIES.VIEW_SHARED_JOB />;
}

const JOB_VACANCIES = {
  CREATE_JOB: lazy(() => import("../pages/jobVacancy/createJob.itme")),
  VIEW_JOBS: lazy(() => import("../pages/jobVacancy/viewJobs.itme")),
  VIEW_DETAILED_JOB: lazy(() => import("../pages/jobVacancy/viewDetailedJob.itme")),
  EDIT_JOB: lazy(() => import("../pages/jobVacancy/editJob.itme")),
  VIEW_SHARED_JOB: lazy(() => import("../pages/itp/viewDetailedVacancy/sharedDetailedVacancy.itme")),
};

const STATUS_AND_MISC = {
  IN_DEVELOPMENT_PAGE: lazy(() => import("../pages/shared/inDevelopmentPage.itme")),
  NOT_FOUND_PAGE_404: lazy(() => import("../pages/shared/404page.itme")),
  // PRIVACY_POLICY: lazy(() => import("../pages/commonTextPages/privacyPolicy.itme")),
  // TERMS_AND_CONDITIONS: lazy(() => import("../pages/commonTextPages/termsNConditions.itme")),
  EARN: lazy(() => import("../pages/commonTextPages/earn.itme")),
  LEARN: lazy(() => import("../pages/commonTextPages/learn.itme")),
  CONNECT: lazy(() => import("../pages/commonTextPages/connect.itme")),
  BUILD: lazy(() => import("../pages/commonTextPages/build.itme")),
  RESOURCES: lazy(() => import("../pages/commonTextPages/resources.itme")),
};

const LANDING_PAGES = {
  // recruiter
  RECRUITER_LANDING_PAGE: lazy(() => import("../pages/landingPage/recruiterLanding")),
  ITP_LANDING_PAGE: lazy(() => import("../pages/landingPage/iTPLanding")),
  CHOOSE_YOUR_PATH_PAGE: lazy(() => import("../pages/landingPage/chooseYourPath")),
};

const AUTH = {
  // where you prompt for mail
  AUTH_FORGOT_PASSWORD_MAIL: lazy(() => import("../pages/auth/forgotPassword.itme")),

  // get new password form
  AUTH_FORGOT_PASSWORD_APPLY: lazy(() => import("../pages/auth/changePassword.itme")),

  // common login
  AUTH_LOGIN: lazy(() => import("../pages/auth/authLogin.itme")),

  // register as a recruiter
  // AUTH_REGISTER_RECRUITER : lazy(() => import('')),

  // register as a ITP
  // AUTH_REGISTER_ITP : lazy(() => import('')),
};

const RECRUITER = {
  RECRUITER_DASHBOARD: lazy(() => import("../pages/recruiter/recruiterDashboard.itme")),
  CREATE_RECRUITER: lazy(() => import("../pages/recruiter/createRecruiter.itme")),
  VIEW_TEAM_MEMBERS: lazy(() => import("../pages/recruiter/viewTeamMembers.itme")),
  CREATE_INVITED_RECRUITER: lazy(() => import("../pages/recruiter/createInvitedRecruiter.itme")),
  RECRUITER_EMAIL_VERIFY: lazy(() => import("../pages/recruiter/verifyRecruiterEmail.itme")),
  VIEW_DETAILED_CANDIDATE: lazy(() => import("../pages/itp/viewDetailedCandidate.itme")),
};

const ITP = {
  VIEW_ITP: lazy(() => import("../pages/itp/viewCandidate.itme")),
  SAVED_ITP: lazy(() => import("../pages/itp/savedCandidates.itme")),
  ITP_EMAIL_VERIFY: lazy(() => import("../pages/itp/verifyITPEmail.itme")),
  CREATE_ITP: lazy(() => import("../pages/itp/signupITP.itme")),
  ITP_DASHBOARD: lazy(() => import("../pages/itp/dashboard/itpDashboard.itme")),
  ITP_VACANCIES: lazy(() => import("../pages/itp/viewJobVacancies/itpVacancies.itme")),
  ITP_PROFILE: lazy(() => import("../pages/itp/profile/itpProfile.itme")),
  VIEW_DETAILED_JOB: lazy(() => import("../pages/itp/viewDetailedVacancy/itpDetailedVacancy.itme")),
  REFER_A_FRIEND: lazy(() => import("../pages/itp/referAFriend/referAFriend.itme")),
};

// eslint-disable-next-line no-unused-vars
const CHAT = {
  CHATPAGE: lazy(() => import("../components/chat/chat.itme")),
};

// eslint-disable-next-line no-unused-vars
const SUPER_ADMIN = {
  LOGIN: lazy(() => import("../pages/super_admin/adminAuthLogin.itme")),
  DASHBOARD: lazy(() => import("../pages/super_admin/adminDashboard.itme")),
  MANAGE_ACCOUNTS: lazy(() => import("../pages/super_admin/manageAccounts.itme")),
};

const authPages = [
  {
    path: AuthMenu.commonLogin.path,
    element: (
      <ProtectedRoute pageContext={AuthMenu.commonLogin}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <AUTH.AUTH_LOGIN />
        </motion.div>
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: AuthMenu.forgotPassword.path,
    element: (
      <ProtectedRoute pageContext={AuthMenu.forgotPassword}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <AUTH.AUTH_FORGOT_PASSWORD_MAIL />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: AuthMenu.changePassword.path,
    element: (
      <ProtectedRoute pageContext={AuthMenu.changePassword}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <AUTH.AUTH_FORGOT_PASSWORD_APPLY />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
];

const vacancyPages = [
  /* Job Vacancy Pages */
  {
    path: AsideMenu.createJobVacancy.path,
    element: (
      <ProtectedRoute pageContext={AsideMenu.createJobVacancy}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <JOB_VACANCIES.CREATE_JOB />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: AsideMenu.viewJobVacancies.path,
    element: (
      <ProtectedRoute pageContext={AsideMenu.viewJobVacancies}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <JOB_VACANCIES.VIEW_JOBS />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: MenuWithoutAside.editJobVacancy.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.editJobVacancy}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <JOB_VACANCIES.EDIT_JOB />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: MenuWithoutAside.viewDetailedJobVacancy.path,
    element: (
      <DetailedVacancySwitch />
    ),
    exact: true,
  },
];

const statusAndMiscPages = [
  {
    path: MenuWithoutAside.inDevelopment.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.inDevelopment}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <STATUS_AND_MISC.IN_DEVELOPMENT_PAGE />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: MenuWithoutAside.page404.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.page404}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <STATUS_AND_MISC.NOT_FOUND_PAGE_404 />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  // {
  //   path: MenuWithoutAside.privacyPolicy.path,
  //   element: (
  //     <ProtectedRoute pageContext={MenuWithoutAside.privacyPolicy}>
  //       <motion.div
  //         initial={{ opacity: 0 }}
  //         animate={{ opacity: 1 }}
  //         exit={{ opacity: 0 }}
  //         style={{ width: "100%", height: "100%" }}
  //       >
  //         <STATUS_AND_MISC.PRIVACY_POLICY />
  //       </motion.div>

  //     </ProtectedRoute>
  //   ),
  //   exact: true,
  // },
  // {
  //   path: MenuWithoutAside.termsNconditions.path,
  //   element: (
  //     <ProtectedRoute pageContext={MenuWithoutAside.termsNconditions}>
  //       <motion.div
  //         initial={{ opacity: 0 }}
  //         animate={{ opacity: 1 }}
  //         exit={{ opacity: 0 }}
  //         style={{ width: "100%", height: "100%" }}
  //       >
  //         <STATUS_AND_MISC.TERMS_AND_CONDITIONS />
  //       </motion.div>

  //     </ProtectedRoute>
  //   ),
  //   exact: true,
  // },

  {
    path: MenuWithoutAside.resources.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.resources}>
        <STATUS_AND_MISC.RESOURCES />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: MenuWithoutAside.earn.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.earn}>
        <STATUS_AND_MISC.EARN />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: MenuWithoutAside.learn.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.learn}>
        <STATUS_AND_MISC.LEARN />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: MenuWithoutAside.connect.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.connect}>
        <STATUS_AND_MISC.CONNECT />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: MenuWithoutAside.build.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.build}>
        <STATUS_AND_MISC.BUILD />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: MenuWithoutAside.dashboardEarn.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.dashboardEarn}>
        <STATUS_AND_MISC.EARN />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: MenuWithoutAside.dasboardLearn.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.dasboardLearn}>
        <STATUS_AND_MISC.LEARN />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: MenuWithoutAside.dashboardConnect.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.dashboardConnect}>
        <STATUS_AND_MISC.CONNECT />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: MenuWithoutAside.dashboardBuild.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.dashboardBuild}>
        <STATUS_AND_MISC.BUILD />
      </ProtectedRoute>
    ),
    exact: true,
  },
];

const landingPages = [
  {
    path: LandingMenu.recruiterLandingPage.path,
    element: (
      <ProtectedRoute pageContext={LandingMenu.recruiterLandingPage}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <LANDING_PAGES.RECRUITER_LANDING_PAGE />
        </motion.div>
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: LandingMenu.iTPLandingPage.path,
    element: (
      <ProtectedRoute pageContext={LandingMenu.iTPLandingPage}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <LANDING_PAGES.ITP_LANDING_PAGE />
        </motion.div>
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: LandingMenu.chooseYourPathPage.path,
    element: (
      <ProtectedRoute pageContext={LandingMenu.chooseYourPathPage}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <LANDING_PAGES.CHOOSE_YOUR_PATH_PAGE />
        </motion.div>
      </ProtectedRoute>
    ),
    exact: true,
  },
];

const recruiterPages = [
  {
    path: AsideMenu.recruiterDashboard.path,
    element: (
      <ProtectedRoute pageContext={AsideMenu.recruiterDashboard} customRedirect>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <DashboardSwitch />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: AuthMenu.verifyRecruiterEmail.path,
    element: (
      <ProtectedRoute pageContext={AuthMenu.verifyRecruiterEmail}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <RECRUITER.RECRUITER_EMAIL_VERIFY />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: AuthMenu.createRecruiter.path,
    element: (
      <ProtectedRoute pageContext={AuthMenu.createRecruiter}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <RECRUITER.CREATE_RECRUITER />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: AsideMenu.viewTeamMembers.path,
    element: (
      <ProtectedRoute pageContext={AsideMenu.viewTeamMembers}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <RECRUITER.VIEW_TEAM_MEMBERS />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: MenuWithoutAside.createInvitedRecruiter.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.createInvitedRecruiter}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <RECRUITER.CREATE_INVITED_RECRUITER />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: MenuWithoutAside.viewDetailedCandidate.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.viewDetailedCandidate}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <RECRUITER.VIEW_DETAILED_CANDIDATE />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
];

const iTPPages = [
  {
    path: AsideMenu.recruiterDashboard.path,
    element: (
      <ProtectedRoute pageContext={AsideMenu.recruiterDashboard} customRedirect>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <DashboardSwitch />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: ITPAsideMenu.jobVacancies.path,
    element: (
      <ProtectedRoute pageContext={ITPAsideMenu.jobVacancies} customRedirect>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <ITP.ITP_VACANCIES />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: AuthMenu.verifyITPEmail.path,
    element: (
      <ProtectedRoute pageContext={AuthMenu.verifyITPEmail}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <ITP.ITP_EMAIL_VERIFY />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: MenuWithoutAside.signupITPaccount.path,
    element: (
      <ProtectedRoute pageContext={MenuWithoutAside.signupITPaccount}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <ITP.CREATE_ITP />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: AsideMenu.viewCandidate.path,
    element: (
      <ProtectedRoute pageContext={AsideMenu.viewCandidate}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <ITP.VIEW_ITP />
        </motion.div>

        {/* <STATUS_AND_MISC.IN_DEVELOPMENT_PAGE /> */}
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: AsideMenu.savedCandidates.path,
    element: (
      <ProtectedRoute pageContext={AsideMenu.savedCandidates}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <ITP.SAVED_ITP />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: ITPAsideMenu.viewProfile.path,
    element: (
      <ProtectedRoute pageContext={ITPAsideMenu.viewProfile}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <ITP.ITP_PROFILE />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: ITPAsideMenu.openSourceProjects.path,
    element: (
      <ProtectedRoute pageContext={ITPAsideMenu.openSourceProjects}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <STATUS_AND_MISC.IN_DEVELOPMENT_PAGE />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: ITPAsideMenu.referAFriend.path,
    element: (
      <ProtectedRoute pageContext={ITPAsideMenu.referAFriend}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <ITP.REFER_A_FRIEND />
        </motion.div>

      </ProtectedRoute>
    ),
    exact: true,
  },
];

const chatPages = [
  {
    path: AsideMenu.chat.path,
    element: (
      <ProtectedRoute pageContext={AsideMenu.chat}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <CHAT.CHATPAGE />
        </motion.div>

        {/* <STATUS_AND_MISC.IN_DEVELOPMENT_PAGE /> */}
      </ProtectedRoute>
    ),
    exact: true,
  },
];

const superAdminPages = [
  {
    path: AsideMenu.viewCandidate.path,
    element: (
      <ProtectedRoute pageContext={AsideMenu.viewCandidate}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <ITP.VIEW_ITP />
        </motion.div>
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: SuperAdminMenu.saLoginPage.path,
    element: (
      <ProtectedRoute pageContext={SuperAdminMenu.saLoginPage}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <SUPER_ADMIN.LOGIN />
        </motion.div>
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: SuperAdminMenu.saDashboard.path,
    element: (
      <ProtectedRoute pageContext={SuperAdminMenu.saDashboard}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <SUPER_ADMIN.DASHBOARD />
        </motion.div>
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: SuperAdminMenu.saManageAccounts.path,
    element: (
      <ProtectedRoute pageContext={SuperAdminMenu.saManageAccounts}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: "100%", height: "100%" }}
        >
          <SUPER_ADMIN.MANAGE_ACCOUNTS />
        </motion.div>
      </ProtectedRoute>
    ),
    exact: true,
  },
];

const contents = [
  ...vacancyPages,
  ...statusAndMiscPages,
  ...recruiterPages,
  ...landingPages,
  ...iTPPages,
  ...chatPages,
  ...authPages,
  ...superAdminPages,
];

export default contents;
