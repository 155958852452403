import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useToasts } from "react-toast-notifications";
import { useAccount } from "wagmi";
import classNames from "classnames";
import { useNavigate, createSearchParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import { HeaderLeft, HeaderRight } from "../../../layout/Header/Header";
import ThemeContext from "../../../contexts/themeContext";
import useDarkMode from "../../../hooks/useDarkMode";
import Popovers from "../../../components/bootstrap/Popovers";
import Toasts from "../../../components/bootstrap/Toasts";
import ToastInstaince from "../../../helpers/toastIntaince";
import { CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import { MenuWithoutAside } from "../../menu.itme";
import useMobileState from "../../../hooks/useMobileState";

// eslint-disable-next-line react/prop-types
function CommonHeaderRight({ beforeChildren, afterChildren, pageName }) {
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();
  const userType = localStorage.getItem("userType");
  const { addToast, toastStack } = useToasts();
  const navigate = useNavigate();
  const { isMobileState } = useMobileState();

  // Get dark mode status
  useEffect(() => {
    const data = window.localStorage.getItem("DARK_MODE_STATE");
    if (data !== null) setDarkModeStatus(JSON.parse(data));
  }, []);

  // Set dark mode status
  useEffect(() => {
    window.localStorage.setItem("DARK_MODE_STATE", darkModeStatus);
  }, [darkModeStatus]);

  const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
  const styledBtn = {
    color: darkModeStatus ? "btn-color-dark" : "btn-color-light",
    hoverShadow: "default",
    size: "lg",
  };

  const { address, isConnected } = useAccount();

  const copyAddress = async () => {
    await navigator.clipboard.writeText(address);
    const instaince = ToastInstaince(toastStack, "Copied");

    if (!instaince) {
      addToast(
        <Toasts icon="ClipboardCheck" iconColor="primary" title="Copied" isDismiss>
          You are successfully copied the Wallet ID.
        </Toasts>,
        {
          autoDismiss: true,
          autoDismissTimeout: 4000,
        },
      );
    }
  };

  return (
    <>
      {userType === "ITP"
        ? (
          <HeaderLeft className={classNames({ row: !isMobileState })}>
            <div className={classNames({ "col-auto": !isMobileState, "mt-2": isMobileState })}>
              <Button
                isLink
                color="light"
                size="sm"
                className="fs-5"
                onClick={() => {
                  navigate({
                    pathname: MenuWithoutAside.dashboardEarn.path,
                    search: `?${createSearchParams({
                      isDashboard: true,
                    })}`,
                  });
                }}
              >
                Earn
              </Button>
            </div>
            <div className={classNames({ "col-auto": !isMobileState })}>
              <Button
                isLink
                color="light"
                size="sm"
                className="fs-5"
                onClick={() => {
                  navigate({
                    pathname: MenuWithoutAside.dasboardLearn.path,
                    search: `?${createSearchParams({
                      isDashboard: true,
                    })}`,
                  });
                }}
              >
                Learn
              </Button>
            </div>
            <div className={classNames({ "col-auto": !isMobileState })}>
              <Button
                isLink
                color="light"
                size="sm"
                className="fs-5"
                onClick={() => {
                  navigate({
                    pathname: MenuWithoutAside.dashboardBuild.path,
                    search: `?${createSearchParams({
                      isDashboard: true,
                    })}`,
                  });
                }}
              >
                Build
              </Button>
            </div>
            <div className={classNames({ "col-auto": !isMobileState })}>
              <Button
                isLink
                color="light"
                size="sm"
                className="fs-5"
                onClick={() => {
                  navigate({
                    pathname: MenuWithoutAside.dashboardConnect.path,
                    search: `?${createSearchParams({
                      isDashboard: true,
                    })}`,
                  });
                }}
              >
                Connect
              </Button>
            </div>
          </HeaderLeft>
        )
        : (
          <HeaderLeft>
            <CardLabel>
              <CardTitle tag="h4" className="h5">
                {pageName}
              </CardTitle>
            </CardLabel>
          </HeaderLeft>
        )}
      <HeaderRight>
        <div className="row g-3">
          {beforeChildren}
          {/* Wallet ID */}
          {isConnected ? (
            <div className="col-auto d-flex align-items-center">
              <Popovers trigger="hover" desc="Copy Wallet ID">
                <Button
                  {...styledBtn}
                  onClick={copyAddress}
                  className="rounded-pill"
                >
                  <div className="fs-5">
                    {" "}
                    {address?.slice(0, 5)}
                    ...
                    {address?.slice(-4)}
                  </div>
                </Button>
              </Popovers>
            </div>
          ) : null}
          {/* Dark Mode */}
          <div className="col-auto">
            <Popovers trigger="hover" desc="Dark / Light mode">
              <Button
                {...styledBtn}
                icon={darkModeStatus ? "DarkMode" : "LightMode"}
                onClick={() => setDarkModeStatus(!darkModeStatus)}
                aria-label="Toggle fullscreen"
                data-tour="dark-mode"
              />
            </Popovers>
          </div>

          {/* Full Screen */}
          <div className="col-auto">
            <Popovers trigger="hover" desc="Fullscreen">
              <Button
                {...styledBtn}
                icon={fullScreenStatus ? "FullscreenExit" : "Fullscreen"}
                onClick={() => setFullScreenStatus(!fullScreenStatus)}
                aria-label="Toggle dark mode"
              />
            </Popovers>
          </div>
          {afterChildren}
        </div>
      </HeaderRight>
    </>
  );
}
CommonHeaderRight.propTypes = {
  beforeChildren: PropTypes.node,
  afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
  beforeChildren: null,
  afterChildren: null,
};

export default CommonHeaderRight;
