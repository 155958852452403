export const cropContainer = {
  padding: 0,
  position: "relative",
  width: "100%",
  height: "25vh",
  background: "#333",
};

export const cropper = {
  position: "absolute",
};
