import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import ThemeContext from "../../contexts/themeContext";
import Content from "../Content/Content";
import FooterRoutes from "../Footer/FooterRoutes";
import HeaderRoutes from "../Header/HeaderRoutes";
import WrapperOverlay from "./WrapperOverlay";

export function WrapperContainer({ children, className, ...props }) {
  const { rightPanel } = useContext(ThemeContext);
  return (
    <div
      className={classNames("wrapper", { "wrapper-right-panel-active": rightPanel }, className)}
      {...props}
    >
      {children}
    </div>
  );
}
WrapperContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
WrapperContainer.defaultProps = {
  className: null,
};

function Wrapper() {
  return (
    <>
      <WrapperContainer>
        <HeaderRoutes />
        <Content />
        <FooterRoutes />
      </WrapperContainer>
      <WrapperOverlay />
    </>
  );
}

export default Wrapper;
