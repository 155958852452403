import gql from "graphql-tag";

const GET_S3_URL = gql`
  mutation Mutation($fileInput: fileInput!) {
    getS3SignedUrl(fileInput: $fileInput) {
      signedUrl
    }
  }
`;
export default GET_S3_URL;
