import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../../../components/bootstrap/Modal";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import TextArea from "../../../components/bootstrap/forms/Textarea";
import LoaderButton from "../shared/buttons/LoaderButton.itme";
import GET_S3_URL from "../../api/fileUploader/fileUploaderMutation";
import Alert from "../../../components/bootstrap/Alert";
import Button from "../../../components/bootstrap/Button";
import GET_ORGANIZATION_BY_ID from "../../api/organization/organizationQuery.itme";
import UPDATE_ORGANIZATION from "../../api/organization/organizationMutation.itme";
import ImageUploader from "../shared/formInputs/ImageUploader.itme";
import useDarkMode from "../../../hooks/useDarkMode";

export function OrganizationEditModal({ showModalState, setShowModalState }) {
  const { themeStatus } = useDarkMode();

  OrganizationEditModal.propTypes = {
    showModalState: PropTypes.bool.isRequired,
    setShowModalState: PropTypes.func,
  };
  OrganizationEditModal.defaultProps = {
    setShowModalState: () => null,
  };

  const [showModal, setShowModal] = useState(showModalState);
  const [isRemoveImage] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [cropImageData, setCropImageData] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const [response, setResponse] = useState("");
  const [responseColor, setResponseColor] = useState("");

  const avatarLink = "";

  // ======================== Form input validation ==================================
  const formValidation = Yup.object().shape({
    organizationName: Yup.string().required("Required"),
    organizationLinkedIn: Yup.string().url("Please enter a valid url").nullable(),
    organizationFacebook: Yup.string().url("Please enter a valid url").nullable(),
    organizationWebsite: Yup.string().url("Please enter a valid url").nullable(),
  });

  const formik = useFormik({
    initialValues: {
      organizationAddress: "",
      organizationName: "",
      organizationPicture: "",
      taxNumber: "",
      organizationLinkedIn: "",
      organizationFacebook: "",
      organizationWebsite: "",
    },
    validationSchema: formValidation,
    onSubmit: () => {
      updateOrganization({
        variables: {
          organizationId: localStorage.getItem("organizationId"),
          organization: {
            organizationName: formik.values.organizationName,
            organizationAddress: formik.values.organizationAddress,
            organizationTaxId: formik.values.taxNumber,
            organizationProfilePicture: cropImageData === "" ? avatarLink : formik.values.userImage,
            socialMediaLinks: {
              companyWebsite: formik.values.organizationWebsite,
              companyLinkedIn: formik.values.organizationLinkedIn,
              companyFacebook: formik.values.organizationFacebook,
            },
          },
        },
      });
    },
  });

  const { refetch } = useQuery(GET_ORGANIZATION_BY_ID, {
    variables: {
      organizationId: localStorage.getItem("organizationId"),
    },
    onCompleted: ({ getOrganizationById }) => {
      formik.setFieldValue("organizationName", getOrganizationById.organizationName);
      formik.setFieldValue("organizationAddress", getOrganizationById.organizationAddress);
      formik.setFieldValue("taxNumber", getOrganizationById.organizationTaxId);
      formik.setFieldValue("organizationWebsite", getOrganizationById.socialMediaLinks.companyWebsite);
      formik.setFieldValue("organizationLinkedIn", getOrganizationById.socialMediaLinks.companyLinkedIn);
      formik.setFieldValue("organizationFacebook", getOrganizationById.socialMediaLinks.companyFacebook);
      setCropImageData(getOrganizationById.organizationProfilePicture);
      if (getOrganizationById.organizationProfilePicture === avatarLink) setCropImageData("");
    },
  });

  const [uploadImage, { loading: imageUploading }] = useMutation(GET_S3_URL);

  const handleImageUpload = (image) => {
    if (image !== "") {
      try {
        uploadImage({
          variables: {
            fileInput: {
              fileName: image.name,
              filePath: "/images/recruiter-images",
            },
          },
        }).then(async ({ data }) => {
          setLoadingImage(true);
          await axios.put(data.getS3SignedUrl.signedUrl, image).then((resData) => {
            const imageUrl = resData.config.url.split("?");
            formik.setFieldValue("userImage", imageUrl[0]);
            setCropImageData(imageUrl[0]);
          });
          setLoadingImage(false);
        });
      } catch {
        setResponse("Image Upload Failed");
        setResponseColor("danger");
        setShowResponse(true);
        setTimeout(() => {
          setShowResponse(false);
        }, 1500);
      }
    }
  };

  const handleImageRemove = () => {
    setCropImageData("");
    formik.setFieldValue("organizationPicture", avatarLink);
  };

  const handleModalClose = () => {
    formik.resetForm();
    setShowModal(false);
    setShowModalState(false);
    refetch();
  };

  const [updateOrganization, { loading: savingOrganization }] = useMutation(UPDATE_ORGANIZATION, {
    onCompleted: () => {
      setResponse("Company profile updated successfully");
      setResponseColor("success");
      setShowResponse(true);
      setTimeout(() => {
        setResponse("");
        setResponseColor("warning");
        setShowResponse(false);
      }, 2500);
    },
    onError: () => {
      setResponse("Company profile update failed");
      setResponseColor("danger");
      setShowResponse(true);
      setTimeout(() => {
        setResponse("");
        setResponseColor("warning");
        setShowResponse(false);
      }, 2500);
    },
  });

  return (
    <Modal isOpen={showModal} setIsOpen={setShowModal} isCentered size="md" isStaticBackdrop isScrollable>
      <ModalHeader className="my-2">
        <div className="col-12 h5 fw-bold text-center">Edit Company Profile</div>
      </ModalHeader>
      {showResponse ? (
        <Alert color={responseColor} isLight isDismissible rounded={2} className="mx-3">
          {response}
        </Alert>
      ) : null}
      <ModalBody>
        <div className="col-12">
          <div className="mx-auto mb-5 col-6 rounded-circle">
            <ImageUploader
              avatar
              onChange={(e) => handleImageUpload(e)}
              removeImage={() => {
                formik.setFieldValue("organizationPicture", "");
              }}
              cropImageData={cropImageData}
              isRemoveImage={isRemoveImage}
              setCropImageData={setCropImageData}
              uploading={imageUploading || loadingImage}
            />
            <Button className="mt-2 d-flex col-12 justify-content-center" onClick={() => handleImageRemove()}>
              Remove Image
            </Button>
          </div>
          <FormGroup id="organizationName" isFloating label="Company Name" className="mb-4">
            <Input
              name="organizationName"
              value={formik.values.organizationName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isTouched={formik.touched.organizationName}
              isValid={formik.isValid}
              invalidFeedback={formik.errors.organizationName}
            />
          </FormGroup>
          <FormGroup id="organizationAddress" isFloating label="Address" className="mb-4">
            <TextArea
              name="organizationAddress"
              rows={5}
              value={formik.values.organizationAddress}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isTouched={formik.touched.organizationAddress}
              isValid={formik.isValid}
              invalidFeedback={formik.errors.organizationAddress}
              style={{ minHeight: 85 }}
            />
          </FormGroup>
          <FormGroup id="taxNumber" isFloating label="Company Tax Number" className="mb-4">
            <Input
              name="taxNumber"
              value={formik.values.taxNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isTouched={formik.touched.taxNumber}
              isValid={formik.isValid}
              invalidFeedback={formik.errors.taxNumber}
            />
          </FormGroup>
          <FormGroup id="organizationWebsite" isFloating label="Company Website" className="mb-4">
            <Input
              name="organizationWebsite"
              value={formik.values.organizationWebsite}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isTouched={formik.touched.organizationWebsite}
              isValid={formik.isValid}
              invalidFeedback={formik.errors.organizationWebsite}
            />
          </FormGroup>
          <FormGroup id="organizationFacebook" isFloating label="Company Facebook Profile" className="mb-4">
            <Input
              name="organizationFacebook"
              value={formik.values.organizationFacebook}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isTouched={formik.touched.organizationFacebook}
              isValid={formik.isValid}
              invalidFeedback={formik.errors.organizationFacebook}
            />
          </FormGroup>
          <FormGroup id="organizationLinkedIn" isFloating label="Company LinkedIn Profile" className="mb-4">
            <Input
              name="organizationLinkedIn"
              value={formik.values.organizationLinkedIn}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isTouched={formik.touched.organizationLinkedIn}
              isValid={formik.isValid}
              invalidFeedback={formik.errors.organizationLinkedIn}
            />
          </FormGroup>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-start">
        <LoaderButton label="Save" icon="CheckCircleOutline" onClick={formik.handleSubmit} loadingText="Loading" loading={savingOrganization} />
        <LoaderButton label="Cancel" icon="Close" color={themeStatus === "dark" ? "btn-color-dark" : "btn-color-light"} onClick={() => handleModalClose()} />
      </ModalFooter>
    </Modal>
  );
}

export default OrganizationEditModal;
