import { useState, useEffect } from "react";
import { useWindowSize } from "react-use";
import { isMobile } from "../itme/helpers/screenSize/screenSizeHelper.itme";

export default function useMobileState() {
  const { width } = useWindowSize();
  const [isMaxTabletState, setIsMaxTabletState] = useState(false);
  const [isTabletState, setIsTabletState] = useState(false);
  const [isMobileState, setIsMobileState] = useState(false);
  const [isMinMobileState, setIsMinMobileState] = useState(false);

  useEffect(() => {
    const isMaxTabletRes = isMobile(width, 1200);
    const isTabletRes = isMobile(width, 991);
    const isMobileRes = isMobile(width, 767);
    const isMinMobileRes = isMobile(width, 430);
    if (isMaxTabletRes) {
      setIsMaxTabletState(true);
    } else {
      setIsMaxTabletState(false);
    }
    if (isTabletRes) {
      setIsTabletState(true);
    } else {
      setIsTabletState(false);
    }
    if (isMobileRes) {
      setIsMobileState(true);
    } else {
      setIsMobileState(false);
    }
    if (isMinMobileRes) {
      setIsMinMobileState(true);
    } else {
      setIsMinMobileState(false);
    }
  }, [width]);

  return {
    isMaxTabletState, isTabletState, isMobileState, isMinMobileState,
  };
}
