const ToastInstaince = (stack, title) => {
  let instaince = false;
  if (stack.length !== 0) {
    stack.forEach((toast) => {
      if (toast.content.props.title === title) {
        instaince = true;
      }
    });
  }
  return instaince;
};

export default ToastInstaince;
