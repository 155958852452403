const ColorVariables = {
  SUCCESS: "#46bcaa",
  DANGER: "#f35421",
  ACTIVE: "#6c5dd3",
  WHITE: "#fff",
  BLACK: "#000",
  DARK_GRAY: "#3c3c3b",
  ACTIVE_TRANSPARENT: "rgba(108, 93, 211, 0.4)",
  BORDER: "#eeeeee",
  BACKGROUND: "#ffffff",
  OPTIONS_DARK_BACKGROUND: "#323232",
  OPTIONS_LIGHT_BACKGROUND: "#f8f9fa",
  OPTIONS_SELECTED_DARK_BACKGROUND: "#495057",
  OPTIONS_SELECTED_LIGHT_BACKGROUND: "#9dcaf0",
  OPTIONS_FOCUSED_DARK_BACKGROUND: "#343a40",
  OPTIONS_FOCUSED_LIGHT_BACKGROUND: "rgb(157, 202, 240, 0.3)",
  DARK_MODE_BACKGROUND: "#222323",
  DARK_MODE_BORDER: "rgb(108, 117, 125, 0.3)",
  PLACEHOLDER: "#6c757d",
  GREY: "#939ba2",
  LIGHT_GREY: "#dcdcdc",
  LIGHT_BLACK: "#2A2D36",
  DARK_COLOR: "#1f2128",
  PLACEHOLDER_LIGHT: "#adb5bd",
  LABEL_BACKGROUND: "#bebebe",
  BORDER_SHADOW: "rgba(108, 93, 211, 0.2)",
  BORDER_SHADOW_WARNING: "rgba(243, 84, 33, 0.3)",
  TAG_COLOR: "#adb5bd",
  SHADE_DARK: "#0a0a18",
};

export default ColorVariables;
