import React, { Suspense } from "react";
import ITmeLogo from "../../itme/assets/ITmeLogo";
import Page from "../Page/Page";
import PageWrapper from "../PageWrapper/PageWrapper";
import ContentRoutes from "./ContentRoutes";

const _loading = (
  <PageWrapper>
    <Page>
      <div className="row my-auto align-items-center justify-content-center">
        <div className="text-center mb-4">
          <ITmeLogo width={450} />
        </div>
      </div>
    </Page>
  </PageWrapper>
);

function Content() {
  return (
    <main className="content">
      <Suspense fallback={_loading}>
        <ContentRoutes />
      </Suspense>
    </main>
  );
}

export default Content;
