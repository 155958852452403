import { useLazyQuery } from "@apollo/client";
import classNames from "classnames";
import { motion } from "framer-motion";
import React, { useContext, useState, useEffect } from "react";
import ThemeContext from "../../../contexts/themeContext";
import useAsideTouch from "../../../hooks/useAsideTouch";
import Navigation from "../../../layout/Navigation/Navigation";
import GET_RECRUITER_ACCESS_LEVEL from "../../api/recruiter/recruiterQuery.itme";
import AsideUser from "../../components/shared/asideUser/User.itme";
import AccessLevel from "../../data/accessLevels.itme";
import { AsideMenu, ITPAsideMenu, SuperAdminSideMenu } from "../../menu.itme";
import Brand from "../Brand/Brand";

function Aside() {
  const { asideStatus, setAsideStatus } = useContext(ThemeContext);
  const userType = localStorage.getItem("userType");
  const { asideStyle, touchStatus, hasTouchButton } = useAsideTouch();
  const navLinks = [];

  const isModernDesign = process.env.REACT_APP_MODERN_DESIGN === "false";
  const recruiterID = localStorage.getItem("recruiterId");

  const [asideMenu, setAsideMenu] = useState(navLinks);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [getRecruiterAcessLevel] = useLazyQuery(GET_RECRUITER_ACCESS_LEVEL);

  // const [seed, setSeed] = useState(1); // trick used to rerender the aside component
  useEffect(() => {
    if (userType === AccessLevel.SUPER_ADMIN) {
      setIsSuperAdmin(true);
      setAsideMenu(SuperAdminSideMenu);
    } else if (userType === AccessLevel.ITP) {
      setAsideMenu(ITPAsideMenu);
    } else {
      getRecruiterAcessLevel({
        variables: {
          recruiterId: recruiterID,
        },
        async onCompleted({ getAccessLevelById }) {
          if (getAccessLevelById === AccessLevel.ADMIN) {
            setAsideMenu(AsideMenu);
          }
          if (getAccessLevelById === AccessLevel.RECRUITER) {
            Object.values(AsideMenu).forEach((link) => {
              if (link.accessLevel.includes(AccessLevel.RECRUITER)) {
                navLinks.push(link);
              }
            });
            setAsideMenu(navLinks);
          }
          if (getAccessLevelById === AccessLevel.VIEWER) {
            const tempObj = { ...AsideMenu };
            delete tempObj.createJobVacancy;
            delete tempObj.chat;
            setAsideMenu(tempObj);
          }
        },
      });
    }
  }, []);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("asideStatus")) === null) {
      localStorage.setItem("asideStatus", asideStatus);
    } else {
      setAsideStatus(JSON.parse(localStorage.getItem("asideStatus")));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("asideStatus", asideStatus);
  }, [asideStatus]);

  return (
    !userType ? null : (
      <motion.aside
        style={asideStyle}
        className={classNames(
          "aside",
          { open: JSON.parse(localStorage.getItem("asideStatus")) },
          {
            "aside-touch-bar": hasTouchButton && isModernDesign,
            "aside-touch-bar-close": !touchStatus && hasTouchButton && isModernDesign,
            "aside-touch-bar-open": touchStatus && hasTouchButton && isModernDesign,
          },
        )}
      >
        <div className="aside-head">
          <Brand isSuperAdmin={isSuperAdmin} asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
        </div>
        <div className="aside-body">
          <Navigation menu={asideMenu} id="aside-dashboard" />
        </div>
        <AsideUser />
      </motion.aside>
    )
  );
}

export default Aside;
