export const isMobile = (screenWidth, breakpointWidth) => {
  if (screenWidth > breakpointWidth) {
    return false;
  }
  return true;
};

export const isScreenWidthRange = (screenWidth, breakpointStartValue, breakpointEndValue) => {
  if (screenWidth >= breakpointStartValue && screenWidth < breakpointEndValue) {
    return true;
  }
  return false;
};
