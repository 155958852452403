// ADMIN, RECRUITER, VIEWER, ITP, ANY
export const AsideMenu = {
  recruiterDashboard: {
    id: "recruiterDashboard",
    text: "Dashboard",
    path: "/dashboard",
    icon: "Home",
    accessLevel: "RECRUITER, ADMIN, VIEWER",
    loginStatus: true,
    redirectURL: "/login",
  },
  viewJobVacancies: {
    id: "viewJobVacancies",
    text: "Job Vacancies",
    path: "/view-jobs",
    icon: "WorkOutline",
    accessLevel: "RECRUITER, ADMIN, VIEWER, ITP",
    loginStatus: true,
    redirectURL: "/login",
  },
  createJobVacancy: {
    id: "createJobVacancy",
    text: "Create Job Vacancy",
    path: "/create-job",
    icon: "PlusLg",
    accessLevel: "RECRUITER, ADMIN",
    loginStatus: true,
    redirectURL: "/login",
  },
  viewCandidate: {
    id: "viewCandidate",
    text: "View IT Professionals",
    path: "/view-candidates",
    icon: "person-badge",
    accessLevel: "RECRUITER, ADMIN, VIEWER",
    loginStatus: true,
    redirectURL: "/login",
  },
  savedCandidates: {
    id: "savedCandidates",
    text: "Saved IT Professionals",
    path: "/saved-candidates",
    icon: "save",
    accessLevel: "RECRUITER, ADMIN, VIEWER",
    loginStatus: true,
    redirectURL: "/login",
  },
  viewTeamMembers: {
    id: "viewTeamMembers",
    text: "Manage Team",
    path: "/manage-team",
    icon: "Groups",
    accessLevel: "ADMIN, RECRUITER, VIEWER",
    loginStatus: true,
    redirectURL: "/login",
  },
  chat: {
    id: "chat",
    text: "Chat",
    path: "/chat",
    icon: "chat-right-text",
    accessLevel: "RECRUITER, ADMIN, ITP",
    loginStatus: true,
    redirectURL: "/login",
  },
};

export const ITPAsideMenu = {
  itpDashboard: {
    id: "itpDashboard",
    text: "Dashboard",
    path: "/dashboard",
    icon: "Home",
    accessLevel: "ITP",
    loginStatus: true,
    redirectURL: "/login",
  },
  viewProfile: {
    id: "viewProfile",
    text: "View Profile",
    path: "/profile",
    icon: "person-circle",
    accessLevel: "ITP",
    loginStatus: true,
    redirectURL: "/login",
  },
  jobVacancies: {
    id: "jobVacancies",
    text: "Job Vacancies",
    path: "/vacancies",
    accessLevel: "ITP",
    icon: "bag",
    loginStatus: true,
    redirectURL: "/login",
  },
  openSourceProjects: {
    id: "openSourceProjects",
    text: "Open Source Projects",
    path: "/op-projects",
    accessLevel: "ITP",
    icon: "card-checklist",
    loginStatus: true,
    redirectURL: "/login",
  },
  chat: {
    id: "chat",
    text: "Chat",
    path: "/chat",
    accessLevel: "ITP",
    icon: "chat-right-text",
    loginStatus: true,
    redirectURL: "/login",
  },
  referAFriend: {
    id: "referAFriend",
    text: "Refer A Friend",
    path: "/refer-a-friend",
    accessLevel: "ITP",
    icon: "People",
    loginStatus: true,
    redirectURL: "/login",
  },
};

export const SuperAdminSideMenu = {
  saDashboard: {
    id: "saDashboard",
    text: "Dashboard",
    path: "/admin-dashboard",
    icon: "Home",
    accessLevel: "SUPER_ADMIN",
    loginStatus: true,
    redirectURL: "/admin-login",
  },
  saManageAccounts: {
    id: "saManageAccounts",
    text: "Manage Accounts",
    path: "/manage-accounts",
    icon: "List",
    accessLevel: "SUPER_ADMIN",
    loginStatus: true,
    redirectURL: "/admin-login",
  },
};

export const MenuWithoutAside = {
  editJobVacancy: {
    id: "editJobVacancy",
    text: "Edit Job Vacancy",
    path: "/edit-job",
    icon: "PlusLg",
    accessLevel: "RECRUITER, ADMIN, VIEWER",
    loginStatus: true,
    redirectURL: "/login",
  },
  viewDetailedJobVacancy: {
    id: "viewDetailedJobVacancy",
    text: "Job Vacancy Details",
    path: "/view-job-details",
    icon: "WorkOutline",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/login",
  },
  // In development page
  inDevelopment: {
    id: "inDevelopment",
    text: "In Development Page",
    path: "/in-development",
    icon: "PlusLg",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/login",
  },
  createInvitedRecruiter: {
    id: "createInvitedRecruiter",
    text: "Sign Up",
    path: "/sign-up/invited-recruiter",
    icon: "PersonOutline",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/login",
  },
  signupITPaccount: {
    id: "signupITPaccount",
    text: "Sign Up",
    path: "/sign-up/ITP",
    icon: "PersonOutline",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/login",
  },

  // Candidate Pages

  viewDetailedCandidate: {
    id: "viewDetailedCandidate",
    text: "IT Professional Details",
    path: "/view-candidate-details",
    icon: "Groups",
    accessLevel: "RECRUITER, ADMIN, VIEWER, SUPER_ADMIN",
    loginStatus: true,
    redirectURL: "/login",
  },
  page404: {
    id: "404Page",
    text: "Page 404",
    path: "/404",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/dashboard",
  },
  // privacyPolicy: {
  //   id: "privacyPolicy",
  //   text: "Privacy Policy",
  //   path: "/privacy-policy",
  //   icon: "ReportGmailerrorred",
  //   accessLevel: "ANY",
  //   loginStatus: false,
  //   redirectURL: "/dashboard",
  // },
  // termsNconditions: {
  //   id: "termsNconditions",
  //   text: "Terms and Conditions",
  //   path: "/terms-and-conditions",
  //   icon: "ReportGmailerrorred",
  //   accessLevel: "ANY",
  //   loginStatus: false,
  //   redirectURL: "/dashboard",
  // },

  resources: {
    id: "resources",
    text: "Resources",
    path: "/resources",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/login",
  },

  learn: {
    id: "learn",
    text: "Learn",
    path: "/learn",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/login",
  },
  earn: {
    id: "earn",
    text: "Earn",
    path: "/earn",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/login",
  },
  connect: {
    id: "connect",
    text: "Connect",
    path: "/connect",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/login",
  },
  build: {
    id: "build",
    text: "Build",
    path: "/build",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/login",
  },
  dasboardLearn: {
    id: "dasboardLearn",
    text: "Learn",
    path: "/dashboard-learn",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: true,
    redirectURL: "/dashboard",
  },
  dashboardEarn: {
    id: "dashboardEarn",
    text: "Earn",
    path: "/dashboard-earn",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: true,
    redirectURL: "/dashboard",
  },
  dashboardConnect: {
    id: "dashboardConnect",
    text: "Connect",
    path: "/dashboard-connect",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: true,
    redirectURL: "/dashboard",
  },
  dashboardBuild: {
    id: "dashboardBuild",
    text: "Build",
    path: "/dashboard-build",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: true,
    redirectURL: "/dashboard",
  },
};

export const AuthMenu = {
  verifyRecruiterEmail: {
    id: "verifyRecruiterEmail",
    text: "Sign Up Recruiter",
    path: "/verify-email/recruiter",
    icon: "PersonOutline",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/dashboard",
  },
  verifyITPEmail: {
    id: "verifyITPEmail",
    text: "Sign Up IT Professional",
    path: "/verify-email/ITP",
    icon: "PersonOutline",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/dashboard",
  },
  createRecruiter: {
    id: "createRecruiter",
    text: "Sign Up",
    path: "/sign-up/recruiter",
    icon: "PersonOutline",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/dashboard",
  },
  commonLogin: {
    id: "commonLogin",
    text: "Login",
    path: "/login",
    icon: "PersonOutline",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/dashboard",
  },
  forgotPassword: {
    id: "forgotPassword",
    text: "Forgot Password",
    path: "/forgot-password",
    icon: "PersonOutline",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/dashboard",
  },
  changePassword: {
    id: "changePassword",
    text: "Change Password",
    path: "/change-password",
    icon: "PersonOutline",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/dashboard",
  },
};

export const LandingMenu = {
  recruiterLandingPage: {
    id: "recruiterLandingPage",
    text: "Welcome!",
    path: "/recruiter",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/dashboard",
  },
  iTPLandingPage: {
    id: "iTPLandingPage",
    text: "Welcome!",
    path: "/ITP",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/dashboard",
  },
  chooseYourPathPage: {
    id: "chooseYourPathPage",
    text: "Choose your path!",
    path: "/choose-your-path",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/dashboard",
  },
  resources: {
    id: "resources",
    text: "ITme Resources",
    path: "/resources",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/dashboard",
  },
};

export const SuperAdminMenu = {
  // login + other super admin endpoints
  saLoginPage: {
    id: "superAdminLoginPage",
    text: "Welcome!",
    path: "/admin-login",
    icon: "ReportGmailerrorred",
    accessLevel: "ANY",
    loginStatus: false,
    redirectURL: "/admin-dashboard",
  },
  ...SuperAdminSideMenu,
};
