import React from "react";
import Header from "../../../layout/Header/Header";
import CommonHeaderRight from "./CommonHeaderRight.itme";

function DefaultNavbar({ pageTitle }) {
  return (
    <Header>
      <CommonHeaderRight pageName={pageTitle} />
    </Header>
  );
}

export default DefaultNavbar;
