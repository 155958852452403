import gql from "graphql-tag";

const CREATE_CONTACT_ADMIN = gql`
  mutation CreateContactAdmin($contactAdminData: ContactAdminInput!) {
    createContactAdmin(contactAdminData: $contactAdminData) {
      status
      message
    }
  }
`;

export default CREATE_CONTACT_ADMIN;
