/* eslint-disable react/prop-types, react/destructuring-assignment, react-hooks/exhaustive-deps, no-else-return */
import classNames from "classnames";
import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import { useDropzone } from "react-dropzone";
import Button from "../../../../components/bootstrap/Button";
import Spinner from "../../../../components/bootstrap/Spinner";
import Icon from "../../../../components/icon/Icon";
import useDarkMode from "../../../../hooks/useDarkMode";
import useMobileState from "../../../../hooks/useMobileState";
import ColorVariables from "../../../common/colorVariables.itme";
import requiredErrorStyle from "../../../data/styleObjects";
import ImageCropper from "../imageCropper/ImageCropper.itme";

function ImageUploader(props) {
  const [file, setFile] = useState([]);
  const [croppedImageData, setCroppedImageData] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const { darkModeStatus } = useDarkMode();
  const { isMobileState } = useMobileState();

  const baseStyle = {
    padding: "none",
    alignItems: "center",
    zIndex: -1,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: darkModeStatus ? ColorVariables.DARK_MODE_BORDER : ColorVariables.BORDER,
    borderStyle: "dashed",
    backgroundColor: darkModeStatus ? ColorVariables.DARK_MODE_BACKGROUND : ColorVariables.BACKGROUND,
    color: ColorVariables.PLACEHOLDER,
    transition: "border .3s ease-in-out",
    minHeight: "178px",
  };

  const baseAvatarStyle = {
    padding: "none",
    alignItems: "center",
    zIndex: -1,
    borderWidth: 0,
    borderRadius: 300,
    borderColor: darkModeStatus ? ColorVariables.DARK_MODE_BORDER : ColorVariables.BORDER,
    backgroundColor: darkModeStatus ? ColorVariables.DARK_MODE_BACKGROUND : ColorVariables.BACKGROUND,
    color: ColorVariables.PLACEHOLDER,
    transition: "border .3s ease-in-out",
    minHeight: isMobileState ? "120px" : "150px",
    maxWidth: isMobileState ? "120px" : "150px",
    margin: "auto",
  };

  const activeStyle = {
    borderColor: ColorVariables.ACTIVE,
  };

  const acceptStyle = {
    borderColor: ColorVariables.SUCCESS,
  };

  const rejectStyle = {
    borderColor: ColorVariables.DANGER,
  };

  useEffect(() => {
    if (file.length !== 0 && (croppedImageData === "" || null)) {
      setOpenModal(true);
    }
  }, [file]);

  useEffect(() => {
    if (croppedImageData !== ("" || null)) {
      props.onChange(croppedImageData);
      setOpenModal(false);
    } else {
      setFile([]);
    }
  }, [croppedImageData]);

  const onDrop = useCallback((acceptedFile) => {
    setFile(
      acceptedFile.map((fileData) => Object.assign(fileData, {
        preview: URL.createObjectURL(fileData),
      })),
    );
  }, []);

  const {
    getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject,
  } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, image/jpg",
    maxSize: "2097152", // image size should be less than 2mb
  });

  const style = useMemo(
    () => ({
      ...(props.avatar ? baseAvatarStyle : baseStyle),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(props.noImage ? requiredErrorStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, props],
  );

  const thumbStyle = {
    width: "100%",
    minHeight: 200,
    maxHeight: 200,
    objectFit: "cover",
  };
  const thumbAvatarStyle = {
    width: "100%",
    maxHeight: 150,
    objectFit: "cover",
    borderRadius: 200,
  };
  const thumbs = (
    <div key="thumb">
      <img src={props.cropImageData} alt="thumb" style={props.avatar ? thumbAvatarStyle : thumbStyle} />
    </div>
  );

  useEffect(() => {
    if (props.isRemoveImage) {
      setCroppedImageData("");
      props.setCropImageData("");
      props.removeImage();
    }
  }, [props.isRemoveImage]);

  const imageClearButton = (
    <div className="position-absolute top-0 end-0">
      <Button
        color="light"
        icon="XCircleFill"
        isLink
        shadow="none"
        onClick={() => {
          setCroppedImageData("");
          props.setCropImageData("");
          props.removeImage();
        }}
      />
    </div>
  );

  const imagePlaceholder = (
    <div>
      {props.avatar ? (
        <>
          <div className="position-absolute top-50 start-50 translate-middle">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <Icon size="9x" icon="PersonOutline" color="light" />
            </div>
          </div>
          <div
            className={classNames("position-absolute top-100 fw-bold pt-2", {
              "start-20": isMobileState,
              "start-30": !isMobileState,
            })}
          >
            Upload Image
          </div>
        </>
      ) : (
        <div className="position-absolute top-50 start-50 translate-middle">
          <div className="d-flex justify-content-center align-items-center flex-column">
            <Icon size="4x" icon="FileEarmarkImage" color="light" />
            <div className="pt-4 text-center">
              <b>Choose a file </b>
              or drag it here
              <br />
              <span className="text-muted">*</span>
              <small>must be a jpg/png and the size less than 2mb</small>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      {openModal ? <ImageCropper image={file[0]} setCroppedImageData={setCroppedImageData} setOpenModal={setOpenModal} cropCircle={props.avatar} /> : ""}
      <section className="position-relative">
        {props.uploading ? (
          <div {...getRootProps({ style })} className="mt-0">
            <div className="position-absolute top-50 start-50 translate-middle">
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            {!props.cropImageData ? "" : imageClearButton}
            <div {...getRootProps({ style })} className="mt-0">
              <input {...getInputProps()} />
              {!props.cropImageData ? imagePlaceholder : <aside>{thumbs}</aside>}
            </div>
            <span style={props.noImage ? requiredErrorStyle : { display: "none" }}>Required!</span>
          </>
        )}
      </section>
    </>
  );
}

export default ImageUploader;
/* eslint-enable react/prop-types, react/destructuring-assignment, react-hooks/exhaustive-deps, no-else-return */
