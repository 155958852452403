import gql from "graphql-tag";

export const VERIFY_TOKEN_ITP = gql`
  query VerifyITPToken($token: String!) {
    verifyITPToken(token: $token) {
      status
      message
    }
  }
`;

export const GET_CANDIDATE = gql`
  query GetCandidateByID($candidateId: ID!) {
    getCandidateByID(candidateId: $candidateId) {
      _id
      candidateName
      candidateProfilePicture
      candidateEmail
      candidateState
      isTopCandidate
      description
      username
      programmingLanguages
      frameworks
      libraries
      databases
      spokenLanguage
      otherSkills
      grossSalary
      location
      yearsOfExperience
      jobPositionPrefered
      currentPosition
      companyName
      candidateWebLink
      candidateLinkedInLink
      candidateGitHubLink
      candidateStackoverflowLink
      jobContractTypePrefered
      jobLocationPrefered
      jobLocationTypePrefered
      expectedSalaryPrefered
      jobLevelPrefered
      technologyStackPrefered
      candidateContactNumber
      eHashed
      status
    }
  }
`;

export const GET_SAVED_VACANCY_IDS = gql`
query GetCandidateByID($candidateId: ID!) {
  getCandidateByID(candidateId: $candidateId) {
    savedVacancies
  }
}
`;

export const GET_CANDIDATES = gql`
  query GetCandidateByIDs($candidateIds: [ID!]) {
    getCandidateByIDs(candidateIds: $candidateIds) {
      _id
      candidateName
      candidateProfilePicture
      candidateEmail
      candidateState
      isTopCandidate
      description
      username
      frameworks
      libraries
      databases
      spokenLanguage
      otherSkills
      grossSalary
      location
      yearsOfExperience
      jobPositionPrefered
    }
  }
`;

export const GET_TOP_CANDIDATES = gql`
 query GetTopCandidates {
    getTopCandidates {
      _id
      candidateName
      candidateProfilePicture
      candidateEmail
      candidateState
      isTopCandidate
      description
      username
      frameworks
      libraries
      databases
      spokenLanguage
      otherSkills
      grossSalary
      location
      yearsOfExperience
      jobPositionPrefered
      status
    }
  }
`;

export const GET_ALL_CANDIDATES = gql`
  query GetAllCandidates {
    getAllCandidates {
      _id
      candidateName
      candidateProfilePicture
      candidateEmail
      candidateState
      isTopCandidate
      description
      username
      programmingLanguages
      frameworks
      libraries
      databases
      spokenLanguage
      otherSkills
      grossSalary
      location
      yearsOfExperience
      jobPositionPrefered
      status
    }
  }
`;

export const GET_ALL_CANDIDATES_ADMIN = gql`
  query Query {
    getAllCandidatesAdmin {
      _id
      candidateName
      isTopCandidate
      candidateProfilePicture
      candidateEmail
      candidateState
      lastLogin
      responseRate
      chatsOpened
    }
  }
`;

export const GET_SAVED_CANDIDATES = gql`
  query GetSavedCandidates {
    getSavedCandidatesByRecruiter {
      id
      candidateName
      candidateProfilePicture
      candidateEmail
      candidateState
      isTopCandidate
      description
      username
      frameworks
      libraries
      databases
      spokenLanguage
      otherSkills
      grossSalary
      location
      yearsOfExperience
      jobPositionPrefered
    }
  }
`;

export const FILTER_CANDIDATES = gql`
  query GetFilteredCandidates($filterObj: CandidateFilterObj) {
    getFilteredCandidates(filterObj: $filterObj) {
      _id
      username
      isTopCandidate
      programmingLanguages
      frameworks
      databases
      candidateProfilePicture
      yearsOfExperience
      status
    }
  }
`;

export const GET_CANDIDATE_ACTIVE_STATUS = gql`
query getCandidateActiveStatus($username: String!) {
  getCandidateActiveStatus(username: $username)
}
`;
