const RecruiterMessages = {
  RECRUITER_CREATED_SUCCESSFULLY_HEADER: "Recruiter Created Successfully!",
  RECRUITER_CREATED_SUCCESSFULLY_MESSAGE: "Please check your email for a registration success email",
  RECRUITER_CREATED_FAILED_HEADER: "Recruiter Creation Failed!",
  RECRUITER_CREATED_FAILED_MESSAGE: "Please try again later",
  VERIFICATION_EMAIL_SENT_FAILED_HEADER: "Failed!",
  VERIFICATION_EMAIL_SENT_FAILED_MESSAGE: "Verification email sending failed. Please try again later.",
  TOKEN_VERIFICATION_FAILED_HEADER: "Failed!",
  TOKEN_VERIFICATION_FAILED_MESSAGE: "Verification email sending failed. Please try again later.",
  FORGOT_PASSWORD_EMAIL_SENT_SUCCESS_HEADER: "Success!",
  FORGOT_PASSWORD_EMAIL_SENT_SUCCESS_MESSAGE: "Reset password email sent successfully. Please check your inbox.",
  FORGOT_PASSWORD_EMAIL_SENT_FAILED_HEADER: "Failed!",
  FORGOT_PASSWORD_EMAIL_SENT_FAILED_MESSAGE: "Reset password email sending failed. Please try again later.",
  RESET_PASSWORD_EMAIL_SENT_SUCCESS_HEADER: "Success!",
  RESET_PASSWORD_EMAIL_SENT_SUCCESS_MESSAGE: "Password Reset Successfully. Please login with your new password.",
  RESET_PASSWORD_EMAIL_SENT_FAILED_HEADER: "Failed!",
  RESET_PASSWORD_EMAIL_SENT_FAILED_MESSAGE: "Resetting password failed. Please try again later.",
  INVITE_TEAMMATE_HEADER: "Invite Teammate",
  INVITE_TEAMMATE_MESSAGE: "Your teammate will get an email that gives them access to your team",
  DELETE_RECRUITER_CONFIRMATION_HEADER: "You are about to delete your team member",
  DELETE_RECRUITER_CONFIRMATION_MESSAGE: "Do you really want to delete your team member? Ownership of all job vacancies he/she created will be transferred to organization creator",
  DELETE_RECRUITER_SUCCESS_HEADER: "Deleted Successfully!",
  DELETE_RECRUITER_SUCCESS_MESSAGE: "Team member has been successfully deleted",
  DELETE_RECRUITER_FAILED_HEADER: "Failed..!",
  DELETE_RECRUITER_FAILED_MESSAGE: "Team member deleting failed. Please try again later.",
  INCORRECT_CURRENT_PASSWORD: "Try again that is not your current password.",
  NEW_EQUAL_OLD_PASSWORD: "The new password cannot be equal to the previous password",
  RECRUITER_PASSWORD_UPDATE_FAILED: "Password change failed. Please try again later",
  RECRUITER_PASSWORD_UPDATE_SUCCESSFUL: "Your password has been changed successfully",
  RECRUITER_UPDATED_SUCCESSFULLY: "Recruiter Updated Successfully",
  RECRUITER_UPDATE_FAILED: "Recruiter Update Failed. Please try again later",
  CONTACT_ADMIN_SUCCESSFULLY: "Your message is successfully sent to the ITme admin.",
  CONTACT_ADMIN_FAILED: "Your message cannot send now. Please try again later.",
  LIMITED_INVITE_TEAMMATES_HEADER: "Sorry..!",
  LIMITED_INVITE_TEAMMATES_MESSAGE: "You have reached the maximum number of team members you can add. You can only add 10 teammates.",
  // REQUIRED_TAX_NUMBER_HEADER: "Alert...!",
  // REQUIRED_TAX_NUMBER_MESSAGE: "Tax number is a required field. Please enter your tax number.",
  RECRUITER_LANDING_VIEW_DETAILED_AND_CHAT_HEADER: "Do you want to contact or view this IT professional? Become a member",
  RECRUITER_LANDING_VIEW_DETAILED_AND_CHAT_MESSAGE: "Login or register with ITme and explore the best IT professionals in the industry.",
};
export default RecruiterMessages;
