import gql from "graphql-tag";

const UPDATE_ORGANIZATION = gql`
  mutation Mutation($organizationId: ID!, $organization: organizationUpdateInput!) {
    updateOrganization(organizationId: $organizationId, organization: $organization) {
      status
      message
    }
  }
`;

export default UPDATE_ORGANIZATION;
