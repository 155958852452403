import gql from "graphql-tag";

export const GET_USER_NAME_BY_ID = gql`
    query Query($userID: String!){
        getUserName(userID: $userID){
            userName
        }
    }
`;

export const GET_WALLET_IDS = gql`
    query Query($userID: String!){
        getWalletIds(userID: $userID){
            walletIds
        }
    }
`;
