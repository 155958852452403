import gql from "graphql-tag";

const GET_RECRUITER_ACCESS_LEVEL = gql`
  query Query($recruiterId: ID!) {
    getAccessLevelById(recruiterId: $recruiterId)
  }
`;

export const GET_RECRUITER_BY_ORGANIZATION = gql`
  query Query($organizationId: ID!, $activeState: ActiveState) {
    getRecruitersByOrganization(organizationId: $organizationId, activeState: $activeState) {
      id
      organizationId
      recruiterName
      recruiterAccessLevel
      recruiterProfilePicture
      recruiterEmail
      recruiterState
      recruiterLastLogin
      savedCandidates
    }
  }
`;

export const GET_ALL_RECRUITERS = gql`
  query Query {
    getAllRecruiters {
      id
      organizationId
      recruiterName
      recruiterAccessLevel
      recruiterProfilePicture
      recruiterEmail
      recruiterState
      recruiterLastLogin
      savedCandidates
    }
  }
`;

export const VERIFY_RECRUITER_TOKEN = gql`
  query Query($token: String!) {
    verifyRecruiterToken(token: $token) {
      status
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  query forgotRecruiterPasswordQuery($email: String!) {
    recruiterForgotPassword(email: $email) {
      status
      message
    }
  }
`;

export const GET_RECRUITER_BY_ID = gql`
  query Query($recruiterId: ID!) {
    getRecruiterByID(recruiterId: $recruiterId) {
      recruiterName
      recruiterAccessLevel
      recruiterProfilePicture
    }
  }
`;

export const CHECK_USERNAME_RECRUITER = gql`
  query Query($username: String!) {
    checkUsernameAvailability(username: $username)
  }
`;
export const CHECK_EMAIL = gql`
query Query($email: String!) {
  checkEmailAvailability(email: $email)
}
`;

export default GET_RECRUITER_ACCESS_LEVEL;
