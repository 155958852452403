import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import axios from "axios";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import classNames from "classnames";
import Jazzicon from "react-jazzicon";
import Alert from "../../../../components/bootstrap/Alert";
import Button, { ButtonGroup } from "../../../../components/bootstrap/Button";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../../components/bootstrap/Dropdown";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import FormText from "../../../../components/bootstrap/forms/FormText";
import Input from "../../../../components/bootstrap/forms/Input";
import Textarea from "../../../../components/bootstrap/forms/Textarea";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../../../../components/bootstrap/Modal";
import CREATE_CONTACT_ADMIN from "../../../api/contactAdmin/contactAdminMutation.itme";
import GET_S3_URL from "../../../api/fileUploader/fileUploaderMutation";
import { CHANGE_PASSWORD, EDIT_RECRUITER, CHECK_SSO_DEFAULT_PASSWORD } from "../../../api/recruiter/recruiterMutation.itme";
import { DEACTIVATE_ITP } from "../../../api/candidate/candidate.mutation.itme";
import { GET_RECRUITER_BY_ID } from "../../../api/recruiter/recruiterQuery.itme";
import { GET_USER_NAME_BY_ID } from "../../../api/user/userQuery.itme";
import RecruiterMessages from "../../../common/notificationMessages/recruiterMessages.itme";
import AccessLevel from "../../../data/accessLevels.itme";
import { RecruiterErrorCodes } from "../../../data/recruiterData.itme";
import { AuthMenu } from "../../../menu.itme";
import OrganizationModal from "../../organization/editProfileModal.itme";
import LoaderButton from "../buttons/LoaderButton.itme";
import ImageUploader from "../formInputs/ImageUploader.itme";
import useDarkMode from "../../../../hooks/useDarkMode";
import Avatar from "../../../../components/Avatar";
import { GET_CANDIDATE } from "../../../api/candidate/candidateQuery.itme";
import useMobileState from "../../../../hooks/useMobileState";

export const initialUserState = {
};

export function UserSettings({
  handleLogout, refetchUser, showModalState, setShowModalState, actionType, showContactModal, showDeactivateProfile,
}) {
  UserSettings.propTypes = {
    showModalState: PropTypes.bool.isRequired,
    actionType: PropTypes.bool.isRequired,
    showContactModal: PropTypes.bool.isRequired,
    setShowModalState: PropTypes.func,
    handleLogout: PropTypes.func,
    refetchUser: PropTypes.func,
  };
  UserSettings.defaultProps = {
    setShowModalState: () => null,
    handleLogout: () => null,
    refetchUser: () => null,
  };

  useEffect(() => {
    checkSSODefaultPassword();
  }, [actionType, checkSSODefaultPassword]);

  const { themeStatus } = useDarkMode();
  const { isMobileState } = useMobileState();

  const [user, setUser] = useState(initialUserState);
  const [showModal, setShowModal] = useState(showModalState);
  const [editProfile, setEditProfile] = useState(actionType);
  const [cropImageData, setCropImageData] = useState("");
  const [isRemoveImage, setIsRemoveImage] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [response, setResponse] = useState("");
  const [responseColor, setResponseColor] = useState("");

  const avatarLink = "";
  const [uploadImage, { loading: imageUploading }] = useMutation(GET_S3_URL);

  // ======================== Form input validation ==================================
  const formValidation = Yup.object().shape({
    firstName: editProfile ? Yup.string().required("Required") : Yup.string(),
    lastName: editProfile ? Yup.string().required("Required") : Yup.string(),
    oldPassword: (editProfile && !stateShowDeactivateProfile)
      ? Yup.string()
      : Yup.string()
        .min(8, "Your password must be longer than 8 characters.")
        .matches(/^(?=.*[A-Za-z])(?=.*[0-9])/, "Must be alphanumeric"),
    deactivepass: (stateShowDeactivateProfile)
      ? Yup.string()
      : Yup.string()
        .min(8, "Your password must be longer than 8 characters.")
        .matches(/^(?=.*[A-Za-z])(?=.*[0-9])/, "Must be alphanumeric"),
    newPassword: (editProfile && !stateShowDeactivateProfile)
      ? Yup.string()
      : Yup.string()
        .required("Required")
        .min(8, "Your password must be longer than 8 characters.")
        .matches(/^(?=.*[A-Za-z])(?=.*[0-9])/, "Must be alphanumeric"),
    reNewPassword: (editProfile && !stateShowDeactivateProfile)
      ? Yup.string()
      : Yup.string()
        .required("Required")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    subject: showContactModal ? Yup.string().required("Required") : Yup.string(),
    message: showContactModal ? Yup.string().required("Required") : Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      reNewPassword: "",
      firstName: "",
      lastName: "",
      userImage: "",
      subject: "",
      message: "",
      deactivepass: "",
    },
    validationSchema: formValidation,
    onSubmit: () => {
      if (stateShowDeactivateProfile) {
        deactivateITP({
          variables: {
            pass: formik.values.deactivepass,
          },
        });
      }
      if (!editProfile && !showContactModal) {
        if (formik.values.oldPassword.trim() === "" && !valSSODefPassowrd) {
          formik.setErrors({ oldPassword: "Old Password cannot be empty" });
          return;
        }
        if (formik.values.oldPassword === formik.values.newPassword) {
          formik.setErrors({ newPassword: "Old Password cannot be used as new password" });
          return;
        }
        if (valSSODefPassowrd) {
          updatePassword({
            variables: {
              oldPassword: "",
              newPassword: formik.values.newPassword,
            },
          });
        } else {
          updatePassword({
            variables: {
              oldPassword: formik.values.oldPassword,
              newPassword: formik.values.newPassword,
            },
          });
        }
      } else if (editProfile && !showContactModal) {
        editRecruiter({
          variables: {
            recruiter: {
              recruiterName: formik.values.firstName.concat(" ").concat(formik.values.lastName),
              recruiterProfilePicture: formik.values.userImage,
            },
          },
        });
      } else if (showContactModal) {
        createContact({
          variables: {
            contactAdminData: {
              subject: formik.values.subject,
              message: formik.values.message,
              userId: localStorage.getItem("recruiterId"),
            },
          },
        });
      }
    },
  });

  const { refetch } = useQuery(GET_RECRUITER_BY_ID, {
    variables: {
      recruiterId: localStorage.getItem("recruiterId"),
    },
    onCompleted: ({ getRecruiterByID }) => {
      setUser(getRecruiterByID);
      const splitName = getRecruiterByID.recruiterName.split(" ");
      formik.setFieldValue("firstName", splitName[0]);
      formik.setFieldValue("lastName", splitName[1]);
      formik.setFieldValue("userImage", getRecruiterByID.recruiterProfilePicture);
      setCropImageData(getRecruiterByID.recruiterProfilePicture);
      if (getRecruiterByID.recruiterProfilePicture === avatarLink) setCropImageData("");
    },
  });

  const handleTabChange = () => {
    // check user sso or other
    setEditProfile(!editProfile);
  };

  const handleImageUpload = (image) => {
    if (image !== "") {
      try {
        uploadImage({
          variables: {
            fileInput: {
              fileName: image.name,
              filePath: "/images/recruiter-images",
            },
          },
        }).then(async ({ data }) => {
          setLoadingImage(true);
          await axios.put(data.getS3SignedUrl.signedUrl, image).then((resData) => {
            const imageUrl = resData.config.url.split("?");
            formik.setFieldValue("userImage", imageUrl[0]);
            setCropImageData(imageUrl[0]);
          });
          setLoadingImage(false);
        });
      } catch {
        setResponse("Image Upload Failed");
        setResponseColor("danger");
        setShowResponse(true);
        setTimeout(() => {
          setShowResponse(false);
        }, 1500);
      }
    }
  };

  const [updatePassword, { loading: passwordUpdating }] = useMutation(CHANGE_PASSWORD, {
    onCompleted: () => {
      setResponseColor("success");
      setResponse(RecruiterMessages.RECRUITER_PASSWORD_UPDATE_SUCCESSFUL);
      setShowResponse(true);
      setTimeout(() => {
        handleLogout();
        window.location.reload();
      }, 200);
    },
    onError: (err) => {
      if (err.graphQLErrors[0].extensions.code === RecruiterErrorCodes.EQUAL_OLD_PASSWORD) {
        setResponseColor("danger");
        setResponse(RecruiterMessages.NEW_EQUAL_OLD_PASSWORD);
        setShowResponse(true);
        setTimeout(() => {
          setShowResponse(false);
        }, 1500);
      } else if (err.graphQLErrors[0].extensions.code === RecruiterErrorCodes.INCORRECT_PASSWORD) {
        setResponseColor("danger");
        setResponse(RecruiterMessages.INCORRECT_CURRENT_PASSWORD);
        setShowResponse(true);
        setTimeout(() => {
          setShowResponse(false);
        }, 1500);
      } else {
        setResponseColor("danger");
        setResponse(RecruiterMessages.RECRUITER_PASSWORD_UPDATE_FAILED);
        setShowResponse(true);
        setTimeout(() => {
          setShowResponse(false);
        }, 1500);
      }
    },
  });

  const [createContact, { loading: creatingContact }] = useMutation(CREATE_CONTACT_ADMIN, {
    onCompleted: () => {
      setResponseColor("success");
      setResponse(RecruiterMessages.CONTACT_ADMIN_SUCCESSFULLY);
      setShowResponse(true);
      formik.setFieldValue("subject", "");
      formik.setFieldValue("message", "");
      formik.setFieldTouched("subject", false);
      formik.setFieldTouched("message", false);
      setTimeout(() => {
        setShowResponse(false);
      }, 1500);
    },
    onError: () => {
      setResponseColor("danger");
      setResponse(RecruiterMessages.CONTACT_ADMIN_FAILED);
      setShowResponse(true);
      setTimeout(() => {
        setShowResponse(false);
      }, 2500);
    },
  });

  const [editRecruiter, { loading: recruiterUpdating }] = useMutation(EDIT_RECRUITER, {
    onCompleted: () => {
      setResponseColor("success");
      setResponse(RecruiterMessages.RECRUITER_UPDATED_SUCCESSFULLY);
      setShowResponse(true);
      refetch();
      refetchUser();
      setTimeout(() => {
        setShowResponse(false);
      }, 1500);
    },
    onError: () => {
      setResponseColor("danger");
      setResponse(RecruiterMessages.RECRUITER_UPDATE_FAILED);
      setShowResponse(true);
      setTimeout(() => {
        setShowResponse(false);
      }, 1500);
    },
  });

  const handleImageRemove = () => {
    setCropImageData("");
    setIsRemoveImage(true);
    formik.setFieldValue("userImage", avatarLink);
  };

  const handleModalClose = () => {
    formik.resetForm();
    setShowModal(false);
    setShowModalState(false);
    refetch();
    refetchUser();
    const splitName = user.recruiterName.split(" ");
    formik.setFieldValue("firstName", splitName[0]);
    formik.setFieldValue("lastName", splitName[1]);
  };

  const [stateShowDeactivateProfile, setShowDeactivateProfile] = useState(false);
  const [deactivateITP, { loading: deactivatingITP }] = useMutation(DEACTIVATE_ITP, {
    onCompleted: ({ authITPDeactivateAcc }) => {
      if (authITPDeactivateAcc) {
        handleLogout();
        window.location.reload();
      }
    },
    onError: (err) => {
      if (err.graphQLErrors[0].extensions.code === RecruiterErrorCodes.EMAIL_NOT_FOUND) {
        setResponseColor("danger");
        setResponse("assa");
        setShowResponse(true);
        setTimeout(() => {
          setShowResponse(false);
        }, 1500);
      } else if (err.graphQLErrors[0].extensions.code === RecruiterErrorCodes.INCORRECT_PASSWORD) {
        setResponseColor("danger");
        setResponse(RecruiterMessages.INCORRECT_CURRENT_PASSWORD);
        setShowResponse(true);
        setTimeout(() => {
          setShowResponse(false);
        }, 1500);
      } else {
        setResponseColor("danger");
        setResponse("Deactivation failed");
        setShowResponse(true);
        setTimeout(() => {
          setShowResponse(false);
        }, 1500);
      }
    },
  });

  const [valSSODefPassowrd, setvalSSODefPassowrd] = useState(false);
  const [checkSSODefaultPassword, { loading: checkingSSODefaultPassword }] = useLazyQuery(CHECK_SSO_DEFAULT_PASSWORD, {
    fetchPolicy: "network-only",
    onCompleted: ({ checkDefaultSSOPassword }) => {
      setvalSSODefPassowrd(checkDefaultSSOPassword);
    },
  });

  return (
    <Modal isOpen={showModal} setIsOpen={setShowModal} isCentered size="md" isStaticBackdrop>
      <ModalHeader className={classNames("my-2", { "d-flex flex-column": isMobileState, "px-4": !isMobileState })}>
        <div
          className={classNames("h5 fw-bold", {
            "col-12 text-center": showContactModal,
            "col-4": !showContactModal,
            "pb-2": isMobileState,
          })}
        >
          {showContactModal ? "Contact ITme" : "My Settings"}
          {/* {showContactModal && localStorage.getItem("userType") !== "ITP" ? "Contact ITme" : "My Settings"} */}
        </div>
        {!showContactModal && (
          <div className="d-flex justify-content-end">
            <ButtonGroup>
              {localStorage.getItem("userType") !== "ITP" ? (
                <>
                  <Button
                    color={
                      // eslint-disable-next-line no-nested-ternary
                      editProfile ? null : themeStatus === "dark" ? "btn-color-dark" : "btn-color-light"
                    }
                    className={editProfile ? "btn-type-default" : null}
                    onClick={handleTabChange}
                  >
                    Edit Profile
                  </Button>
                  <Button
                    color={
                      // eslint-disable-next-line no-nested-ternary
                      !editProfile ? null : themeStatus === "dark" ? "btn-color-dark" : "btn-color-light"
                    }
                    className={!editProfile ? "btn-type-default" : null}
                    onClick={() => {
                      if (showDeactivateProfile) {
                        if (stateShowDeactivateProfile) {
                          setEditProfile(!editProfile);
                          setShowDeactivateProfile(false);
                        }
                        checkSSODefaultPassword();
                        return;
                      }
                      handleTabChange();
                      checkSSODefaultPassword();
                    }}
                  >
                    Change Password
                  </Button>
                </>
              ) : null}

              {localStorage.getItem("userType") === "ITP" && showDeactivateProfile ? (
                <>
                  <Button
                    color={
                      // eslint-disable-next-line no-nested-ternary
                      (editProfile && !stateShowDeactivateProfile && !showContactModal) ? "" : (themeStatus === "dark" ? "btn-color-dark" : "btn-color-light")
                    }
                    className={(!editProfile && !stateShowDeactivateProfile && !showContactModal) ? "btn-type-default" : null}
                    onClick={() => {
                      if (showDeactivateProfile) {
                        if (stateShowDeactivateProfile) {
                          setEditProfile(!editProfile);
                          setShowDeactivateProfile(false);
                        }
                        checkSSODefaultPassword();
                        return;
                      }
                      handleTabChange();
                      checkSSODefaultPassword();
                    }}
                  >
                    Change Password
                  </Button>
                  <Button
                    color={
                      // eslint-disable-next-line no-nested-ternary
                      stateShowDeactivateProfile ? null : themeStatus === "dark" ? "btn-color-dark" : "btn-color-light"
                    }
                    className={stateShowDeactivateProfile ? "btn-type-default" : null}
                    onClick={() => {
                      if (!stateShowDeactivateProfile) {
                        setShowDeactivateProfile(true);
                        setEditProfile(!editProfile);
                      }
                    }}
                  >
                    Deactivate Profile
                  </Button>
                </>
              ) : null}
            </ButtonGroup>
          </div>
        )}
      </ModalHeader>
      {showResponse ? (
        <Alert color={responseColor} isLight isDismissible rounded={2} className="mx-3">
          {response}
        </Alert>
      ) : null}
      <ModalBody>
        <div className="col-12 px-2">
          {(showContactModal && !stateShowDeactivateProfile) && (
            <>
              <FormGroup id="subject" className="col-12">
                <Input
                  placeholder="Subject"
                  name="subject"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isTouched={formik.touched.subject}
                  isValid={formik.isValid}
                  invalidFeedback={formik.errors.subject}
                  required
                />
              </FormGroup>
              <FormGroup id="exampleEventTextarea" className="col-12 my-4">
                <Textarea
                  placeholder="Message"
                  rows={9}
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isTouched={formik.touched.message}
                  isValid={formik.isValid}
                  invalidFeedback={formik.errors.message}
                  required
                />
              </FormGroup>
            </>
          )}
          {editProfile && !showContactModal && !stateShowDeactivateProfile ? (
            <>
              <div className={classNames("mx-auto col-6 rounded-circle mb-0", { "mb-5": !isMobileState || !cropImageData })}>
                <ImageUploader
                  avatar
                  onChange={(e) => { handleImageUpload(e); setIsRemoveImage(false); }}
                  removeImage={() => {
                    formik.setFieldValue("userImage", avatarLink);
                  }}
                  cropImageData={cropImageData}
                  isRemoveImage={isRemoveImage}
                  setCropImageData={setCropImageData}
                  uploading={imageUploading || loadingImage}
                />
                {cropImageData ? (
                  <Button className="d-flex col-12 justify-content-center text-muted" onClick={() => handleImageRemove()}>
                    Remove Image
                  </Button>
                ) : null}
              </div>
              <div className={classNames("mb-4 d-flex", {
                "flex-row": !isMobileState,
                "flex-column": isMobileState,
              })}
              >
                <FormGroup
                  id="firstName"
                  isFloating
                  label="First Name"
                  className={classNames({
                    "col-6 pe-3": !isMobileState,
                    "col-12 pb-4": isMobileState,
                  })}
                >
                  <Input
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isTouched={formik.touched.firstName}
                    isValid={formik.isValid}
                    invalidFeedback={formik.errors.firstName}
                  />
                </FormGroup>
                <FormGroup
                  id="lastName"
                  isFloating
                  label="Last Name"
                  className={classNames({
                    "col-6": !isMobileState,
                    "col-12": isMobileState,
                  })}
                >
                  <Input
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isTouched={formik.touched.lastName}
                    isValid={formik.isValid}
                    invalidFeedback={formik.errors.lastName}
                  />
                </FormGroup>
              </div>
            </>
          ) : null}
          {!editProfile && !stateShowDeactivateProfile && !showContactModal ? (
            <div>
              {!checkingSSODefaultPassword ? (
                <>
                  {valSSODefPassowrd === false ? (
                    <FormGroup id="oldPassword" isFloating label="Current Password" className="mb-4">
                      <Input
                        name="oldPassword"
                        type="password"
                        value={formik.values.oldPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.oldPassword}
                        isValid={formik.isValid}
                        invalidFeedback={formik.errors.oldPassword}
                      />
                    </FormGroup>
                  ) : null}
                  <FormGroup id="newPassword" isFloating label="New password">
                    <Input
                      name="newPassword"
                      type="password"
                      value={formik.values.newPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isTouched={formik.touched.newPassword}
                      isValid={formik.isValid}
                      invalidFeedback={formik.errors.newPassword}
                    />
                  </FormGroup>
                  <FormText className="mb-4">Must be 8 or more characters and contain at least 1 number</FormText>
                  <FormGroup id="reNewPassword" isFloating label="Confirm New Password" className="mb-4">
                    <Input
                      name="reNewPassword"
                      type="password"
                      value={formik.values.reNewPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isTouched={formik.touched.reNewPassword}
                      isValid={formik.isValid}
                      invalidFeedback={formik.errors.reNewPassword}
                    />
                  </FormGroup>
                </>
              ) : null}
            </div>
          ) : null}
          {stateShowDeactivateProfile ? (
            <div className="mb-5">
              {!checkingSSODefaultPassword ? (
                <span>
                  {valSSODefPassowrd ? (
                    <>
                      <h5 className={classNames("w-100 text-center", { "mt-5": !isMobileState })}>Deactivate your profile</h5>
                      <p className="w-100 text-center text-muted">Please set a new password to deactivate your account</p>
                    </>
                  ) : (
                    <>
                      <h5 className={classNames("w-100 text-center", { "mt-5": !isMobileState })}>Enter your password to deactivate your profile</h5>
                      <FormGroup id="deactivepassform" isFloating label="Password" className="mb-4 mt-4">
                        <Input
                          name="deactivepass"
                          type="password"
                          value={formik.values.deactivepass}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isTouched={formik.touched.deactivepass}
                          isValid={formik.isValid}
                          invalidFeedback={formik.errors.deactivepass}
                        />
                      </FormGroup>
                      <FormText className="mb-4 text-center ">After deactivating your account you will be logged out from the system and you can activate your account by logging in again.</FormText>
                    </>
                  )}
                </span>
              ) : null}
            </div>
          ) : null}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-start px-4 pt-0">
        <LoaderButton
          name="changebtn"
          // eslint-disable-next-line no-nested-ternary
          label={showContactModal ? "Send" : (showDeactivateProfile && stateShowDeactivateProfile) ? "Deactivate" : "Save"}
          icon="CheckCircleOutline"
          onClick={() => {
            if (!stateShowDeactivateProfile) {
              formik.handleSubmit();
            } else {
              // eslint-disable-next-line no-lonely-if
              if (formik.values.deactivepass === "") {
                formik.setErrors({ deactivepass: "required" });
                return;
              }
              if (formik.values.deactivepass !== "" && formik.values.deactivepass.length >= 8) {
                deactivateITP({
                  variables: {
                    pass: formik.values.deactivepass,
                  },
                });
              } else {
                formik.setErrors({ deactivepass: "Your password must be longer than 8 characters." });
              }
            }
          }}
          loading={passwordUpdating || recruiterUpdating || creatingContact || deactivatingITP}
          // eslint-disable-next-line no-nested-ternary
          loadingText={showContactModal ? "Sending" : (showDeactivateProfile && stateShowDeactivateProfile) ? "Deactivating" : "Loading"}
        />
        <LoaderButton label="Cancel" icon="Close" color={themeStatus === "dark" ? "btn-color-dark" : "btn-color-light"} onClick={() => handleModalClose()} />
      </ModalFooter>
    </Modal>
  );
}

function AsideUser() {
  const navigate = useNavigate();

  const [user, setUser] = useState(initialUserState);
  const [userName, setUserName] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);

  const { setDarkModeStatus } = useDarkMode();

  const [getRecruiterById, { refetch }] = useLazyQuery(GET_RECRUITER_BY_ID);
  const [getCandidateById] = useLazyQuery(GET_CANDIDATE);

  const userType = localStorage.getItem("userType");

  useEffect(() => {
    if (userType === AccessLevel.SUPER_ADMIN) {
      // super admin is also a recruiter
      getRecruiterById({
        variables: {
          recruiterId: localStorage.getItem("recruiterId"),
        },
        onCompleted: ({ getRecruiterByID }) => {
          setUser(getRecruiterByID);
          setUserProfile(getRecruiterByID.recruiterProfilePicture);
        },
      });
    } else if (userType === AccessLevel.ITP) {
      getCandidateById({
        variables: {
          candidateId: localStorage.getItem("recruiterId"),
        },
        onCompleted: ({ getCandidateByID }) => {
          setUser(getCandidateByID);
          setUserProfile(getCandidateByID.candidateProfilePicture);
        },
      });
    } else {
      getRecruiterById({
        variables: {
          recruiterId: localStorage.getItem("recruiterId"),
        },
        onCompleted: ({ getRecruiterByID }) => {
          setUser(getRecruiterByID);
          setUserProfile(getRecruiterByID.recruiterProfilePicture);
        },
      });
    }
  }, []);

  // eslint-disable-next-line no-unused-vars
  const { loading } = useQuery(GET_USER_NAME_BY_ID, {
    variables: {
      userID: localStorage.getItem("recruiterId"),
    },
    // eslint-disable-next-line no-shadow
    onCompleted: ({ getUserName }) => {
      setUserName(getUserName.userName);
    },
  });

  const handleLogout = () => {
    navigate(`${AuthMenu.commonLogin.path}`);
    localStorage.clear();
    setDarkModeStatus(process.env.REACT_APP_DARK_MODE === "true");
  };

  const handleProfile = () => {
    setShowContactModal(false);
    setShowModal(true);
  };

  const handleContact = () => {
    setShowContactModal(true);
    setShowModal(true);
  };

  const handleOrganization = () => {
    setShowOrganizationModal(true);
  };

  return (
    <>
      <Dropdown>
        <DropdownToggle hasIcon={false}>
          <div className="user">
            {userType === AccessLevel.ITP
              ? (<Jazzicon diameter={40} seed={userProfile} />)
              : (<Avatar src={userProfile} size={40} color="dark-gray" />)}
            <div className="user-info ms-2">
              <div className="user-name">{userName.length > 20 ? `${userName.slice(0, 15)}...` : userName }</div>
              <div className="user-sub-title">{user.recruiterAccessLevel}</div>
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <Button icon="Logout" onClick={() => handleLogout()}>
              Logout
            </Button>
          </DropdownItem>
          <DropdownItem>
            <Button icon="Person" onClick={() => handleProfile()}>
              My Settings
            </Button>
          </DropdownItem>
          <DropdownItem>
            <Button icon="Envelope" onClick={() => handleContact()}>
              Contact ITme
            </Button>
          </DropdownItem>
          {user.recruiterAccessLevel === AccessLevel.ADMIN ? (
            <DropdownItem>
              <Button icon="PersonVideo3" onClick={() => handleOrganization()}>
                Edit Organization
              </Button>
            </DropdownItem>
          ) : null}
        </DropdownMenu>
      </Dropdown>

      {/* =======================  Profile Settings & Contact Modal ======================== */}

      {(showModal && localStorage.getItem("userType") !== AccessLevel.ITP) && <UserSettings showModalState setShowModalState={setShowModal} handleLogout={handleLogout} refetchUser={refetch} actionType showContactModal={showContactModal} />}
      {(showModal && localStorage.getItem("userType") === AccessLevel.ITP) && <UserSettings showModalState setShowModalState={setShowModal} handleLogout={handleLogout} refetchUser={refetch} actionType={false} showContactModal={showContactModal} showDeactivateProfile />}
      {/* =======================  Organization Settings Modal ======================== */}
      {showOrganizationModal && <OrganizationModal showModalState={showOrganizationModal} setShowModalState={setShowOrganizationModal} />}
    </>
  );
}

export default AsideUser;
