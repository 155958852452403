const AccessLevel = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  RECRUITER: "RECRUITER",
  VIEWER: "VIEWER",
  ITP: "ITP",
  ANY: "ANY",
};

export default AccessLevel;
