/* ************************************************************** */
/* ************** This file is part of the IT-Me **************** */
/* ************************************************************** */

import React, { lazy } from "react";
import { Route, useLocation, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import contents from "../../itme/routes/contentRoutes.itme";

const PAGE_404 = lazy(() => import("../../itme/pages/shared/404page.itme"));
function ContentRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence
      exitBeforeEnter
    >
      <Routes key={location.pathname}>
        {contents.map((page) => (
          <Route key={page.path} {...page} />
        ))}
        <Route path="*" element={<PAGE_404 />} />
      </Routes>
    </AnimatePresence>
  );
}

export default ContentRoutes;
