import ColorVariables from "../common/colorVariables.itme";

const requiredErrorStyle = {
  width: "100%",
  marginTop: "0.25rem",
  fontSize: "0.875em",
  color: ColorVariables.DANGER,
  borderColor: ColorVariables.DANGER,
};

export default requiredErrorStyle;
