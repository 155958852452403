/* eslint-disable react-hooks/exhaustive-deps, react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import Cropper from "react-easy-crop";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../../../../components/bootstrap/Modal";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Button from "../../../../components/bootstrap/Button";
import getCroppedImg from "./croppedImage.itme";
import { cropContainer, cropper } from "./imageCropperStyles.itme";
import useDarkMode from "../../../../hooks/useDarkMode";

function ImageCropper({
  image, setCroppedImageData, setOpenModal, cropCircle,
}) {
  // --------------- Notification Message Modal States ---------------
  const [showModal, setShowModal] = useState(false);
  const [centeredStatus, setCenteredStatus] = useState(false);
  const [headerCloseStatus, setHeaderCloseStatus] = useState(true);

  const { themeStatus } = useDarkMode();

  const initialStatus = () => {
    setCenteredStatus(false);
    setHeaderCloseStatus(true);
  };

  useEffect(() => {
    initialStatus();
    setCenteredStatus(true);
    setShowModal(true);
    setHeaderCloseStatus(false);
  }, []);

  const handleClose = useCallback(() => {
    setShowModal(false);
    setCroppedImageData("");
    setOpenModal(false);
  }, []);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixelValues) => {
    setCroppedAreaPixels(croppedAreaPixelValues);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedData = await getCroppedImg(image.preview, croppedAreaPixels);
      setCroppedImageData(croppedData);
      setShowModal(false);
    } catch (e) {
      // implement error handling
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <Modal isOpen={showModal} isCentered={centeredStatus} setIsOpen={setShowModal} isStaticBackdrop>
      <ModalHeader className="d-flex justify-content-center pt-5 px-5 pb-0" setIsOpen={headerCloseStatus ? setShowModal : null} />
      <ModalBody className="px-5 py-0 d-flex justify-content-center">
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="row" style={cropContainer}>
            <Cropper
              image={image.preview}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              style={cropper}
              aspect={cropCircle ? 1 / 1 : 3 / 1}
              cropShape={cropCircle ? "round" : "rect"}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center px-5 pb-5">
        <div className="row d-flex align-items-center justify-content-center w-100">
          <div className="col-12 text-center px-0">
            <FormGroup>
              <Input type="range" value={zoom} min={1} max={3} step={0.1} size="sm" aria-labelledby="Zoom" onChange={(e) => setZoom(e.target.value)} className="zoom-range" />
            </FormGroup>
          </div>
          <div className="col-12 text-center px-0">
            <Button onClick={showCroppedImage} icon="Crop" className="mx-1 btn-type-default">
              Crop Image
            </Button>
            <Button icon="X" onClick={handleClose} className="mx-1" color={themeStatus === "dark" ? "btn-color-dark" : "btn-color-light"}>
              Cancel
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default ImageCropper;
/* eslint-enable react-hooks/exhaustive-deps, react/prop-types */
