import gql from "graphql-tag";

const SEND_RECRUITER_VERIFICATION_EMAIL = gql`
  mutation Mutation($email: String!) {
    sendRecruiterVerificationEmail(email: $email) {
      status
      message
    }
  }
`;

export const SEND_INVITATION_TEAMMATE_EMAIL = gql`
  mutation Mutation($email: String!, $organizationId: ID!, $accessLevel: String!) {
    sendInviteTeammate(email: $email, organizationId: $organizationId, accessLevel: $accessLevel) {
      status
      message
    }
  }
`;

export const CREATE_ADMIN_RECRUITER = gql`
  mutation CreateAdminRecruiter($token: String!, $username: String!, $recruiterName: String!, $organization: OrganizationInput!, $password: String!) {
    createAdminRecruiter(token: $token, username: $username, recruiterName: $recruiterName, organization: $organization, password: $password) {
      jwt
    }
  }
`;

export const CREATE_ADMIN_RECRUITER_SSO = gql`
  mutation CreateAdminRecruiterSSO($token: String!, $username: String!, $recruiterName: String!, $organization: OrganizationInput!) {
    createAdminRecruiterSSO(token: $token, username: $username, recruiterName: $recruiterName, organization: $organization) {
      jwt
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation Mutation($token: String!, $email: String!) {
    resendRecruiterToken(token: $token, email: $email) {
      status
      message
    }
  }
`;
export const RECRUITER_LOGIN = gql`
  mutation Mutation($email: String!, $password: String!) {
    recruiterLogin(email: $email, password: $password) {
      jwt
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation Mutation($token: String!, $password: String!) {
    resetRecruiterPassword(token: $token, password: $password) {
      status
      message
    }
  }
`;

export const CREATE_INVITED_RECRUITER = gql`
  mutation CreateInvitedRecruiter($recruiterId: ID!, $username: String!, $recruiterInput: RecruiterInput!) {
    createInvitedRecruiter(recruiterId: $recruiterId, username: $username, recruiterInput: $recruiterInput) {
      jwt
    }
  }
`;

export const DELETE_RECRUITER = gql`
  mutation Mutation($recruiterId: ID!, $organizationId: ID!) {
    deleteRecruiter(recruiterId: $recruiterId, organizationId: $organizationId) {
      status
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation Mutation($oldPassword: String!, $newPassword: String!) {
    authChangePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      status
      message
    }
  }
`;

export const CHECK_SSO_DEFAULT_PASSWORD = gql`
  query Query {
    checkDefaultSSOPassword
  }
`;

export const EDIT_RECRUITER = gql`
  mutation Mutation($recruiter: RecruiterInput!) {
    editRecruiter(recruiter: $recruiter) {
      status
      message
    }
  }
`;

export const SAVE_CANDIDATE = gql`
  mutation Mutation($candidateId: ID!) {
    saveCandidate(candidateId: $candidateId) {
      status
      message
    }
  }
`;

export const UN_SAVE_CANDIDATE = gql`
  mutation Mutation($candidateId: ID!) {
    unSaveCandidate(candidateId: $candidateId) {
      status
      message
    }
  }
`;

export default SEND_RECRUITER_VERIFICATION_EMAIL;
