/* ************************************************************** */
/* ************** This file is part of the IT-Me **************** */
/* ************************************************************** */

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../../components/icon/Icon";
import ITmeLogo from "../../assets/ITmeLogo";
import { AsideMenu, SuperAdminMenu } from "../../menu.itme";

function Brand({ asideStatus, setAsideStatus, isSuperAdmin = false }) {
  return (
    <div className="brand">
      <div className="brand-logo">
        {/* add margin left to ITme logo by using "aside-logo" */}
        <h1 className="brand-title mt-5 aside-logo">
          <Link
            to={isSuperAdmin
              ? SuperAdminMenu.saDashboard.path
              : AsideMenu.recruiterDashboard.path}
            aria-label="Logo"
            alt="ITme"
          >
            <ITmeLogo height={70} />
          </Link>
        </h1>
      </div>
      <button type="button" className="btn brand-aside-toggle" aria-label="Toggle Aside" onClick={() => setAsideStatus(!asideStatus)}>
        <Icon icon="ChevronLeft" className="brand-aside-toggle-close" />
        <Icon icon="ChevronRight" className="brand-aside-toggle-open" />
      </button>
    </div>
  );
}
Brand.propTypes = {
  asideStatus: PropTypes.bool.isRequired,
  setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
