import gql from "graphql-tag";

const GET_ORGANIZATION_BY_ID = gql`
  query GetOrganizationById($organizationId: ID!) {
    getOrganizationById(organizationId: $organizationId) {
      organizationName
      organizationTaxId
      organizationOwner
      organizationProfilePicture
      organizationAddress
      socialMediaLinks {
        companyWebsite
        companyLinkedIn
        companyFacebook
      }
    }
  }
`;

export const GET_ORGANIZATIONS_BY_IDS = gql`
  query getOrganizationsByIds($organizationIds: [ID!]) {
    getOrganizationsByIds(organizationIds: $organizationIds) {
      id
      organizationName
    }
  }
`;

export const GET_ALL_ORGANIZATIONS = gql`
  query Query {
    getAllOrganizations {
      id
      organizationName
      teamMembers
      socialMediaLinks {
        companyWebsite
        companyLinkedIn
        companyFacebook
      }
      organizationTaxId
      organizationState
      organizationOwner
      organizationProfilePicture
      organizationAddress
      createdOn
      responseRate
      chatsOpened
    }
  }
`;

export default GET_ORGANIZATION_BY_ID;
